import React from 'react'
import { Form, Button, Stack, Row, Col, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { useCreateSemesterMutation, useGetSemesterQuery, useUpdateSemesterMutation } from './slice/semesterApiSlice'

export const ViewCreateSemester = () => {
  const { id } = useParams()

  if (id) return <Edit semesterId={id} />

  return <FormSemester />
}

const Edit = ({ semesterId }) => {
  const { data: semester = {}, isLoading, isSuccess } = useGetSemesterQuery(semesterId)

  if (isLoading) return <Spinner />
  if (isSuccess) return <FormSemester defaultValue={semester} />
}

const FormSemester = ({ defaultValue = {} }) => {
  const navigate = useNavigate()

  const { register, handleSubmit, reset, formState: { isDirty, isValid } } = useForm({
    defaultValues: defaultValue
  })

  const [createSemester] = useCreateSemesterMutation()
  const [editSemester] = useUpdateSemesterMutation()

  const handleSubmitClicked = async (data) => {
    try {
      if (data._id) {
        await editSemester(data).unwrap()
        // await navigate(`/semesters/${data._id}`, { replace: true })
        await navigate('/semesters', { replace: true })
      } else {
        await createSemester(data).unwrap()
        await navigate('/semesters', { replace: true })
      }
      await reset()
    } catch (err) {
      console.error(err)
    }
  }

  const handleBackClicked = async () => {
    try {
      await reset()
      await navigate('/semesters', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(handleSubmitClicked)}
    >
      <Card>
        <Card.Body>
          <Card.Title>{defaultValue._id ? 'Edit' : 'Create'} Semester</Card.Title>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="Description"
                  {...register('title', {
                    required: true,
                    minLength: 3
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="code">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  placeholder="Code"
                  readOnly={defaultValue.code ? true : false}
                  {...register('code', {
                    required: true,
                    minLength: 3
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="active">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...register('active', {
                    required: true
                  })}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Stack gap={1} direction="horizontal">
            <Button
              onClick={handleBackClicked}
              variant="secondary"
            >
              <i className="bi bi-arrow-left" />&nbsp;
              Back
            </Button>
            <Button
              className="ms-auto"
              type="submit"
              disabled={!isValid || !isDirty}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => reset()}
              variant="secondary"
              disabled={!isDirty}
            >
              Reset
            </Button>
          </Stack>
        </Card.Footer>
      </Card>


    </Form>
  )
}