import React from 'react'
import { Spinner as BSSpinner } from 'react-bootstrap'

export const Spinner = ({ message = 'Fetching data from server. Please wait', animation = 'grow', variant = 'primary' }) => {
  return (
    <div style={{ height: '75vh', background: 'white', zIndex: 1000 }}>
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <BSSpinner animation={animation} size="lg" variant={variant} className="mb-2" />
        <small>&nbsp;{message}</small>
      </div>
    </div>
  )
}