import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, Card, Dropdown, Form, Stack } from 'react-bootstrap'
import { Table } from '../../components/tables/Table'
import { useGetAllTemplatesQuery } from './slice/formApiSlice'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { useDispatch } from 'react-redux'
import { handleFormFilter } from './slice/formSlice'
import { handleAnalyticFilterReset } from '../analytics/slice/analyticSlice'

export const ViewForm = ({
  title = 'Evaluation Tools',
  subtitle = 'All Evaluation Tools',
  hideEmpty = false
}) => {
  const dispatch = useDispatch()
  const [hide, setHide] = useState(hideEmpty)

  const { data: forms = [], isSuccess, isLoading } = useGetAllTemplatesQuery()

  useEffect(() => {
    dispatch(handleAnalyticFilterReset())
  }, [forms])

  const handleFilter = (key, value) =>
    dispatch(handleFormFilter({ key, value }))

  const columns = useMemo(() => [
    {
      Header: '',
      // accessor: '_id',
      id: 'options',
      Cell: ({ row: { original: { _id, total } } }) => <Dropdown>
        <Dropdown.Toggle variant="link" className="p-0">
          View
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Header>View Options</Dropdown.Header>
          <Dropdown.Item as={Link} to={`/evaluations/${_id}/analytics`} hidden={total === 0}><i className="bi bi-graph-up" />{' '}Analytics</Dropdown.Item>
          <Dropdown.Item as={Link} to={`/evaluations/${_id}/data`} hidden={total === 0}><i className="bi bi-bar-chart-steps" />{' '}Raw Data</Dropdown.Item>
          <Dropdown.Divider hidden={total === 0} />
          <Dropdown.Item as={Link} to={`/evaluations/${_id}`}><i className="bi bi-info-square" />{' '}Information</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>

      // <Link to={`${view}/${value}`}>View</Link>
    },
    {
      Header: 'Evaluation Tool Name',
      accessor: 'name',
      Cell: ({ cell: { value } }) =>
        <span className="text-uppercase">{value.toLowerCase()}</span>
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cell: { value } }) =>
        <>
          {
            value.map((c, i) =>
              <span key={i}>
                <Badge
                  as={Button}
                  bg="primary"
                  pill
                  onClick={() => handleFilter('category', c)}
                  className="text-capitalize"
                >
                  {c}
                </Badge>&nbsp;
              </span>
            )
          }
        </>
    },
    {
      Header: 'Departments',
      accessor: 'departments',
      Cell: ({ cell: { value } }) =>
        <>
          {
            value.map((c, i) =>
              <span key={i}>
                <Badge
                  as={Button}
                  bg="primary"
                  pill
                  onClick={() => handleFilter('departments', c)}
                  className="text-capitalize"
                >
                  {c}
                </Badge>&nbsp;
              </span>
            )
          }
        </>
    },
    {
      Header: 'Total Data',
      accessor: 'total'
    },
    // {
    //   Header: 'Date Created',
    //   accessor: 'createdAt',
    //   Cell: ({ cell: { value } }) => moment(value).fromNow()
    // },
    // {
    //   Header: 'Date Updated',
    //   accessor: 'updatedAt',
    //   Cell: ({ cell: { value } }) => moment(value).fromNow()
    // },
    {
      Header: 'Created By',
      // accessor: 'createdBy.fullName',
      Cell: ({ row: { original: { createdBy } } }) =>
        createdBy ? <Badge
          as={Button}
          bg="primary"
          onClick={() => handleFilter('createdBy', createdBy._id)}
        >
          {createdBy.fullName}
        </Badge> : null
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    }
  ], [])

  const data = useMemo(() => forms.templates, [forms])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card className="mb-3">
      <Card.Header>
        <Stack gap={3} direction="horizontal">
          <Form.Check
            className="ms-auto"
            type="switch"
            id="hide-evaluation"
            value={false}
            label="Hide Empty Evaluations"
            onClick={() => setHide(!hide)}
          />
        </Stack>
      </Card.Header>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle>{subtitle} ({forms.total} Entries) </Card.Subtitle>
        <Table
          columns={columns}
          data={hide ? data.filter(f => f.total !== 0) : data}
        />
      </Card.Body>
    </Card>
  )
}