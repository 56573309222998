import React from 'react'
import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

export const LayoutPreference = () => {
  return (
    <Container fluid className="mb-3">
      <Outlet />
    </Container>
  )
}