import React, { useMemo } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormSelectAcademicYear } from '../../../components/forms/selections/FormSelectAcademicYear'
import { FormSelectDepartment } from '../../../components/forms/selections/FormSelectDepartment'
import { FormSelectSemester } from '../../../components/forms/selections/FormSelectSemester'
import { usePatchEvaluationMutation } from '../../evaluation/slice/evaluationApiSlice'

export const ModalEditEvaluation = ({ show, setShow, data }) => {
  const evaluation = useMemo(() => {
    let filter = { ...data }
    delete filter.department
    delete filter.employee
    delete filter.evaluation
    delete filter.summary
    delete filter.respondents
    return filter
  }, [data])

  const { register, handleSubmit, reset, formState: { isDirty, isValid } } = useForm({
    defaultValues: {
      ...evaluation
    }
  })

  const [patchEvaluation, { isLoading }] = usePatchEvaluationMutation()

  const handleClose = () => setShow(false)
  const handleSubmitClicked = async (data) => {

    try {
      const query = {
        params: {
          ...evaluation
        },
        body: Object
          .entries(data)
          .reduce((a, [k, v]) =>
            (v ? (a[k] = v, a) : a), {}
          )
      }

      await patchEvaluation(query).unwrap()
      await reset()
      await handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(handleSubmitClicked)} autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title>Edit Evaluation Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <strong>You are about to edit {data.respondents} evaluation records.</strong>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="employeeId">
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Employee ID"
                  {...register('employeeId', {
                    required: true,
                    disabled: evaluation.employeeId ? false : true
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={9}>
              <FormSelectDepartment
                register={{
                  ...register('departmentCode', {
                    required: true,
                  })
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="subjectCode">
                <Form.Label>Subject Code</Form.Label>
                <Form.Control
                  placeholder="Subject Code"
                  {...register('subjectCode', {
                    required: true,
                    disabled: evaluation.subjectCode ? false : true
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="section">
                <Form.Label>Section</Form.Label>
                <Form.Control
                  placeholder="Section"
                  {...register('section', {
                    required: true,
                    disabled: evaluation.section ? false : true
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormSelectAcademicYear
                register={{ ...register('academicYear', { required: true }) }}
              />
            </Col>
            <Col md={6}>
              <FormSelectSemester
                register={{ ...register('semester', { required: true }) }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                By clicking <strong>Save Changes</strong>, you will be editing {data.respondents} evaluation records with the data you set in this form.
                Please take note that this action is <strong>not reversible</strong>.
                And you are aware of the action you are about to commit
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={!isDirty && !isValid}>
            Yes, I understand
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No, Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}