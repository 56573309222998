import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { Table } from '../../components/tables/Table'
import { useGetAllTermQuery } from './slice/termApiSlice'
import moment from 'moment'
import { Button, Card } from 'react-bootstrap'

export const ViewTerm = () => {
  const { data: terms = [], isLoading, isSuccess } = useGetAllTermQuery()

  const columns = useMemo(() => [
    {
      Header: '',
      id: 'view',
      accessor: '_id',
      Cell: ({ cell: { value } }) => <Link to={`/terms/edit/${value}`}>View</Link>
    },
    {
      Header: 'Description',
      accessor: 'title'
    },
    {
      Header: 'Code',
      accessor: 'code'
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ cell: { value } }) => moment(value).format('MMMM DD, YYYY')
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ cell: { value } }) => moment(value).format('MMMM DD, YYYY')
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row: { original } }) =>
        <Button
          variant="link"
          className="p-0"
          onClick={() => alert(original._id)}
        >
          Delete
        </Button>
    }

  ], [])

  const data = useMemo(() => terms.terms, [terms])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card>
      <Card.Body>
        <Card.Title>Terms</Card.Title>
        <Card.Subtitle>All Terms</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}