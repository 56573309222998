import { createSlice, nanoid } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  alerts: []
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    handleAddNotification: {
      reducer(state, action) {
        state.alerts.push(action.payload)
      },
      prepare(title, message, delay, variant) {
        return {
          payload: {
            id: nanoid(),
            title: title ? title : 'Notification',
            show: true,
            message,
            variant,
            delay: delay ? delay : 5000,
            timestamp: moment().utc().format()
          }
        }
      }
    },
    handleCloseNotification(state, action) {
      const getToastById = state.alerts.find(toast => toast.id === action.payload)
      if (getToastById) getToastById.show = false
    }
  }
})

export default notificationSlice.reducer
export const {
  handleAddNotification,
  handleCloseNotification
} = notificationSlice.actions