import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { env } from '../../config'

export const hrisApiSlice = createApi({
  reducerPath: 'hrisApi',
  baseQuery: fetchBaseQuery({
    baseUrl: env.API_URI,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('token')

      if (token) headers.set('authorization', `Bearer ${token}`)

      return headers
    }
  }),
  tagTypes: [
    'Department',
    'Employee',
    'Evaluation',
    'Preference',
    'Template',
    'Import',
    'User',
  ],
  endpoints: () => ({})
})