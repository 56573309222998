import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'
import { handleAddNotification } from '../../../components/notifications/slice/notificationSlice'

export const importerApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllImportLogs: builder.query({
      query: (params) => ({
        url: '/imports',
        method: 'GET',
        params: params
      }),
      providesTags: ['Evaluation', 'Import', 'Logs']
    }),
    importEvaluation: builder.mutation({
      query: (data) => ({
        url: '/imports/upload',
        method: 'POST',
        body: data.body,
        params: data.params
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Import Evaluation', 'Import Processing', 3000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Import Evaluation', 'Import Successful', 5000, 'success'))
        } catch (err) {
          // `onError` side-effect
          dispatch(handleAddNotification('Import Evaluation', 'Import Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: ['Evaluation', 'Template', 'Import', 'Logs']
    }),
    deleteImport: builder.mutation({
      query: (id) => ({
        url: `/imports/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Delete Import Evaluation', 'Delete Import Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Delete Import Evaluation', 'Delete Import Successful', 3000, 'success'))
        } catch (err) {
          // `onError` side-effect
          dispatch(handleAddNotification('Delete Import Evaluation', 'Delete Import Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: ['Evaluation', 'Template', 'Import', 'Logs']
    })
  }),
  overrideExisting: false
})

export const {
  useGetAllImportLogsQuery,
  useImportEvaluationMutation,
  useDeleteImportMutation,
} = importerApiSlice