import React, { useMemo } from 'react'
import { useDeleteEmployeeMutation, useGetAllEmployeeQuery } from './slice/employeeApiSlice'
import { Table } from '../../components/tables/Table'
import { Card } from 'react-bootstrap'
import { Spinner } from '../../components/spinners/Spinner'
import { Link } from 'react-router-dom'
import { ModalDelete } from '../../components/modals/ModalDelete'
import { ButtonDeleteConfirm } from '../../components/buttons/ButtonDeleteConfirm'

export const ViewEmployee = () => {
  const {
    data: employees = [],
    isLoading,
    isSuccess
  } = useGetAllEmployeeQuery()
  const [deleteEmployee] = useDeleteEmployeeMutation()

  const columns = useMemo(() => [
    {
      Header: 'View',
      accessor: '_id',
      Cell: ({ cell: { value } }) => <Link to={`/employees/edit/${value}`}>View</Link>
    },
    {
      Header: 'Employee Id',
      accessor: 'employeeId'
    },
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    },
    {
      Header: 'Department',
      accessor: 'departmentCode'
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cell: { value } }) => <span className="text-capitalize">{value}</span>
    },
    {
      Header: '',
      id: 'delete',
      Cell: ({ row: { original } }) => <ButtonDeleteConfirm
        data={original}
        action={() => deleteEmployee(original._id)}
      >
        <p>
          <strong>
            {original.firstName} {original.lastName} - {original.department.code}
          </strong>
        </p>
      </ButtonDeleteConfirm>
    }
    // {
    //   Header: 'Active',
    //   accessor: 'active',
    //   Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    // }
  ], [])

  const data = useMemo(() => employees.employees, [employees])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Employees</Card.Title>
        <Card.Subtitle>All Employees</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}