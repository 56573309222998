import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const termApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllTerm: builder.query({
      query: () => '/terms',
      providesTags: ['Term']
    }),
    getTermSelection: builder.query({
      query: () => ({
        url: '/terms/selection',
        method: 'GET',
      }),
      providesTags: ['Term']
    }),
    getTerm: builder.query({
      query: (id) => `/terms/${id}`,
      providesTags: ['Term'],
    }),
    createTerm: builder.mutation({
      query: (body) => ({
        url: '/terms',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Term']
    }),
    updateTerm: builder.mutation({
      query: (body) => ({
        url: `/terms/${body._id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Term']
    }),
    deleteTerm: builder.mutation({
      query: (body) => ({
        url: `/terms/${body._id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Term']
    }),
  }),
  overrideExisting: false
})

export const {
  useGetAllTermQuery,
  useGetTermSelectionQuery,
  useGetTermQuery,
  useCreateTermMutation,
  useUpdateTermMutation,
  useDeleteTermMutation
} = termApiSlice