import React from 'react'
import { Form } from 'react-bootstrap'

export const FormControlFile = ({ register, label, hidden = false }) => {
  return (
    <Form.Group className="mb-3" controlId={register.name} hidden={hidden}>
      <Form.Label>{label ? label : 'Evaluation File'}</Form.Label>
      <Form.Control
        {...register}
        type="file"
      />
      <Form.Text className="text-muted">
        Important! This only accepts a <strong>.csv</strong> file
      </Form.Text>
    </Form.Group>
  )
}