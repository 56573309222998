import React, { useMemo, useState } from 'react'
import { Button, Card, Dropdown, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Table } from '../../components/tables/Table'
import { Spinner } from '../../components/spinners/Spinner'
import { useGetProcessedEvaluationQuery } from '../evaluation/slice/evaluationApiSlice'
import { FilterAnalytics } from './components/FilterAnalytics'
import { ButtonDownloadPDF } from '../evaluation/components/buttons/ButtonDownloadPDF'
import { ButtonDownloadCSV } from '../evaluation/components/buttons/ButtonDownloadCSV'
import { ModalEditEvaluation } from './components/ModalEditEvaluation'
import { ButtonGeneratePDF } from '../../components/buttons/ButtonGeneratePDF'

export const ViewAnalytics = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { formId } = useParams()
  const { category, filter } = useSelector(state => state.analytic)

  const {
    data: evaluations = [],
    isLoading,
    isSuccess,
    isFetching,
  } = useGetProcessedEvaluationQuery({
    formId: formId,
    group: category,
    query: filter
  })

  const columns = useMemo(() => {
    const cols = isSuccess ? [...evaluations.columns] : []

    cols.unshift({
      Header: '',
      id: 'options',
      Cell: ({ row: { original } }) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="link" className="p-0">
              Select
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Header>Download</Dropdown.Header>
              <Dropdown.Item><i className="bi bi-graph-up" />{' '}Generate CSV</Dropdown.Item>
              <Dropdown.Item><i className="bi bi-bar-chart-steps" />{' '}Generate PDF</Dropdown.Item>
              <Dropdown.Divider /> */}
              <Dropdown.Header>Edit</Dropdown.Header>
              <ModalEditData data={original} />
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    })

    return cols
  }, [evaluations])

  const data = useMemo(() => evaluations.evaluations, [evaluations])
  const templates = useMemo(() => evaluations.templates, [evaluations])
  const departments = useMemo(() => evaluations.departments, [evaluations])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <FilterAnalytics
            departments={departments}
            templates={templates}
          />
        </Card.Body>
      </Card>
      {
        isFetching ?
          <Spinner /> :
          <Card className="mb-3">
            {
              data.length > 0 ?
                <Card.Header>
                  <Stack direction="horizontal" gap="1">
                    {/* <ButtonDownloadPDFZip className="my-3" formId={formId} /> */}
                    <span className="ms-auto" />
                    {/* <ButtonDownloadPDF data={data} category={category} /> */}
                    <ButtonDownloadCSV data={data} />
                    <ButtonGeneratePDF data={evaluations} />
                  </Stack>
                </Card.Header>
                :
                null
            }
            <Card.Body>
              <Card.Title>
                Analytics&nbsp;<span className="text-capitalize">{`(${category})`}</span>
              </Card.Title>
              <Card.Subtitle>
                {templates ? templates.name : 'Performance Evaluation Analytics'}&nbsp;({evaluations.count} Entries)
                <br />
              </Card.Subtitle>
              <Table
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
      }
    </>
  )
}

const ModalEditData = ({ data }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <i className="bi bi-info-square" />{' '}Edit Data
      </Dropdown.Item>
      <ModalEditEvaluation show={show} setShow={setShow} data={data} />
    </>
  )
}