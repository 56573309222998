import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Form, Pagination, Stack } from 'react-bootstrap'
import { useParams, useSearchParams } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { Table } from '../../components/tables/Table'
import { useGetAllEvaluationQuery } from './slice/evaluationApiSlice'
import { EvaluationFilter } from './components/sections/EvaluationFilter'
import { useDispatch, useSelector } from 'react-redux'
import { ModalEditEvaluation } from './components/ModalEditEvaluation'
import { handleEvaluationFilter } from './slice/evaluationSlice'

export const ViewEvaluation = () => {
  const dispatch = useDispatch()
  const { formId } = useParams()
  const { filter } = useSelector(state => state.evaluation)
  const [searchParams] = useSearchParams()

  // const {
  //   data: departments = [],
  //   isSuccess: isFilterSuccess,
  // } = useGetAllEvaluationQuery({ formId: formId, academicYear: filter.academicYear, semester: filter.semester })

  const {
    data: evaluations = [],
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllEvaluationQuery({ formId: formId, ...filter })

  useEffect(() => {
    if (searchParams.has('importId')) {
      dispatch(handleEvaluationFilter({ key: 'importId', value: searchParams.get('importId') }))
      dispatch(handleEvaluationFilter({ key: 'page', value: 1 }))
    }
  }, [searchParams])

  const handlePagination = (value) =>
    dispatch(handleEvaluationFilter({ key: 'page', value: value }))

  const handleLimit = (value) =>
    dispatch(handleEvaluationFilter({ key: 'limit', value: value }))

  const handleFilter = (key, value) =>
    dispatch(handleEvaluationFilter({ key, value }))

  const columns = useMemo(() => [
    {
      Header: 'Information',
      columns: [
        {
          Header: 'Department',
          accessor: 'departmentCode',
          Cell: ({ cell: { value } }) =>
            <Button
              onClick={() => handleFilter('departmentCode', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button>
        },
        {
          Header: 'Employee',
          accessor: 'employeeId',
          Cell: ({ cell: { value } }) =>
            <Button
              onClick={() => handleFilter('employeeId', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button>
        }
      ]
    },
    {
      Header: 'Evaluation',
      columns: [
        {
          Header: 'Subject',
          accessor: 'subjectCode',
          Cell: ({ cell: { value } }) =>
            value ? <Button
              onClick={() => handleFilter('subjectCode', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button> : 'N/A'
        },
        {
          Header: 'Section',
          accessor: 'section',
          Cell: ({ cell: { value } }) =>
            value ? <Button
              onClick={() => handleFilter('section', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button> : 'N/A'
        },
      ]
    },
    {
      Header: 'Term',
      columns: [
        {
          Header: 'Academic Year',
          accessor: 'academicYear',
          Cell: ({ cell: { value } }) =>
            <Button
              onClick={() => handleFilter('academicYear', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button>
        },
        {
          Header: 'Semester',
          accessor: 'semester',
          Cell: ({ cell: { value } }) =>
            <Button
              onClick={() => handleFilter('semester', value)}
              className="p-0"
              variant="link"
            >
              {value}
            </Button>
        },
      ]
    },
  ], [])

  const data = useMemo(() => evaluations.evaluations, [evaluations])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <>

      <Card className="mb-3">
        <Card.Body>
          <EvaluationFilter
            departments={evaluations.departments}
          >
            {/* <EditEvaluationModal /> */}
          </EvaluationFilter>
        </Card.Body>
      </Card>

      {

        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Evaluation Data</Card.Title>
            <Card.Subtitle>{evaluations.total} Evaluation Records</Card.Subtitle>
            <Table
              columns={columns}
              data={data}
              isFetching={isFetching}
              limit={filter.limit}
            >
              <div>
                <Form.Select
                  aria-label="Show Results"
                  // value={pageSize}
                  value={evaluations.limit}
                  // onChange={e => { setPageSize(Number(e.target.value)) }}
                  onChange={(e => handleLimit(e.target.value))}
                >
                  {
                    [5, 10, 25, 50, 100, 250, 500]
                      .map(pageSize => (
                        <option key={pageSize} value={pageSize}>Show&nbsp;{pageSize}</option>
                      ))
                  }
                </Form.Select>
              </div>
              <div>
                <span>
                  <strong>
                    {evaluations.currentPage} of {evaluations.totalPages}
                  </strong>{' '}
                </span>
              </div>
              <div>
                <Pagination>
                  <Pagination.First onClick={() => handlePagination(1)} disabled={Number(evaluations.currentPage) === 1} />
                  <Pagination.Prev onClick={() => handlePagination(Number(evaluations.currentPage) - 1)} disabled={Number(evaluations.currentPage) === 1} />
                  <Pagination.Next onClick={() => handlePagination(Number(evaluations.currentPage) + 1)} disabled={Number(evaluations.currentPage) === evaluations.totalPages} />
                  <Pagination.Last onClick={() => handlePagination(evaluations.totalPages)} disabled={Number(evaluations.currentPage) === Number(evaluations.totalPages)} />
                </Pagination>
              </div>

            </Table>
          </Card.Body>
        </Card>
      }
    </>
  )
}

const EditEvaluationModal = () => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Button onClick={() => setShow(true)}>Batch Edit</Button>
      <ModalEditEvaluation show={show} setShow={setShow} />
    </>
  )
}