import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { handleLogout } from '../../../features/login/slice/loginSlice'
import { useChangePasswordMutation } from '../../../features/profile/slice/profileApiSlice'

export const ModalChangePassword = ({ show, setShow, children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [changePassword] = useChangePasswordMutation()

  const { changePassword: requireChangePassword } = useSelector(state => state.authentication.user)

  const { register, handleSubmit, reset, watch, formState: { isValid, errors } } = useForm()

  const handleClose = () => {
    setShow(false)
    reset()
  }

  const onLogoutClicked = async () => {
    try {
      await dispatch(handleLogout())
      await setShow(false)
      await navigate('/login', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  const onChangePasswordClicked = async (data) => {
    try {
      await changePassword(data).unwrap()
      await reset()
      await setShow(false)

      if (requireChangePassword) {
        await dispatch(handleLogout())
        await navigate('/login', { replace: true })
      }

    } catch (err) {
      await reset()
      console.log(err)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <Form
        autoComplete="off"
        onSubmit={handleSubmit(onChangePasswordClicked)}
      >
        <Modal.Header closeButton={!requireChangePassword}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-3">
            Changing your paassword will increase the security of your account.&nbsp;
            <strong>Please do not use common or easy to guess passwords</strong>.&nbsp;
          </div>

          <Form.Group className="mb-3" controlId="currentPassword">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              {...register('currentPassword', {
                required: true,
                minLength: {
                  value: 4
                }
              })}
              placeholder="Current Password"
              type="password"
            />
            <Form.Text className="text-muted">
              {errors['currentPassword']?.message}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              {...register('newPassword', {
                required: true,
                minLength: {
                  value: 8,
                  message: 'New password must be with a minimum of 8 characters'
                },
                validate: (val) => watch('currentPassword') !== val || 'Password must not match with the current password'
              })}
              placeholder="New Password"
              type="password"
            />
            <Form.Text className="text-muted">
              {errors['newPassword']?.message}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="verifyNewPassword">
            <Form.Label>Verify New Password</Form.Label>
            <Form.Control
              {...register('verifyNewPassword', {
                required: true,
                validate: (val) => watch('newPassword') === val || 'Password did not match'
              })}
              placeholder="Verify New Password"
              type="password"
            />
            <Form.Text className="text-muted">
              {errors['verifyNewPassword']?.message}
            </Form.Text>
          </Form.Group>

        </Modal.Body>

        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid}
          >
            Change Password
          </Button>
          {
            requireChangePassword ?
              <Button onClick={onLogoutClicked} variant="secondary">Logout</Button> :
              <Button onClick={handleClose} variant="secondary">Close</Button>
          }
        </Modal.Footer>

      </Form>
    </Modal>
  )

}