import React from 'react'
import { Container } from 'react-bootstrap'

export const Home = () => {
  return (
    <Container fluid>
      Home - Welcome to the Human Resource Management Center Performance Evaluation System
    </Container>
  )
}
