import moment from 'moment'
import React, { useMemo } from 'react'
import { Form, Button, Stack, Row, Col, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { useCreateTermMutation, useGetTermQuery, useUpdateTermMutation } from './slice/termApiSlice'

export const ViewCreateTerm = () => {
  const { id } = useParams()

  if (id) return <Edit semesterId={id} />

  return <FormTerm />
}

const Edit = ({ semesterId }) => {
  const { data: semester = {}, isLoading, isSuccess } = useGetTermQuery(semesterId)

  const data = useMemo(() => {
    return {
      _id: semester._id,
      active: semester.active,
      code: semester.code,
      startDate: moment(semester.startDate).format('YYYY-MM-DD'),
      endDate: moment(semester.endDate).format('YYYY-MM-DD')
    }
  }, [semester])

  if (isLoading) return <Spinner />
  if (isSuccess) return <FormTerm defaultValue={data} />
}

const FormTerm = ({ defaultValue = {} }) => {
  const navigate = useNavigate()

  const { register, handleSubmit, reset, formState: { isDirty, isValid } } = useForm({
    defaultValues: defaultValue
  })

  const [createTerm] = useCreateTermMutation()
  const [editTerm] = useUpdateTermMutation()

  const handleSubmitClicked = async (data) => {
    try {
      if (data._id) {
        await editTerm(data).unwrap()
        // await navigate(`/terms/${data._id}`, { replace: true })
        await navigate('/terms', { replace: true })
      } else {
        await createTerm(data).unwrap()
        await navigate('/terms', { replace: true })
      }
      await reset()
    } catch (err) {
      console.error(err)
    }
  }

  const handleBackClicked = async () => {
    try {
      await reset()
      await navigate('/terms', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(handleSubmitClicked)}
    >
      <Card>
        <Card.Body>
          <Card.Title>{defaultValue._id ? 'Edit' : 'Create'} Term</Card.Title>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  placeholder="Start Date"
                  type="date"
                  {...register('startDate', {
                    required: true,
                    valueAsDate: true
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="codendDatee">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  placeholder="End Date"
                  type="date"
                  {...register('endDate', {
                    required: true,
                    valueAsDate: true
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="code">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  placeholder="Code"
                  readOnly={defaultValue.code ? true : false}
                  {...register('code', {
                    required: true,
                    minLength: 3
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="active">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...register('active', {
                    required: true
                  })}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Stack gap={1} direction="horizontal">
            <Button
              onClick={handleBackClicked}
              variant="secondary"
            >
              <i className="bi bi-arrow-left" />&nbsp;
              Back
            </Button>
            <Button
              className="ms-auto"
              type="submit"
              disabled={!isValid || !isDirty}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => reset()}
              variant="secondary"
              disabled={!isDirty}
            >
              Reset
            </Button>
          </Stack>
        </Card.Footer>
      </Card>
    </Form>
  )
}