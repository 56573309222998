import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'

export const FormSelectEvaluator = ({ register, children, list = [] }) => {
  const options = [
    {
      value: 'assistant principal',
      label: 'Assistant Principal'
    },
    {
      value: 'dean',
      label: 'Dean'
    },
    {
      value: 'director',
      label: 'Director'
    },
    {
      value: 'employee',
      label: 'Employee'
    },
    {
      value: 'faculty',
      label: 'Faculty'
    },
    {
      value: 'head',
      label: 'Head'
    },
    {
      value: 'president',
      label: 'President',
    },
    {
      value: 'principal',
      label: 'Principal'
    },
    {
      value: 'program chair',
      label: 'Program Chair'
    },
    {
      value: 'school head',
      label: 'School Head'
    },
    {
      value: 'students',
      label: 'Students'
    },
    {
      value: 'vice president',
      label: 'Vice President',
    },
  ]

  const selection = useMemo(() => {
    if (list.length > 0)
      return options
        .filter(department =>
          list.includes(department.value)
        )
    return options
  }, [options])

  const isSuccess = true

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Evaluated by</Form.Label>
      <Form.Select {...register}>
        {children ? children : <option value="" disabled>Select Evaluated by</option>}
        {
          isSuccess ? selection
            .map((c, i) =>
              <option value={c.value} key={i}>
                {c.label}
              </option>) : null
        }
      </Form.Select>
      <Form.Text className="text-muted">
        {/* Description */}
      </Form.Text>
    </Form.Group>
  )
}