import React from 'react'
import { Form } from 'react-bootstrap'
import { useGetTemplateSelectionQuery } from '../../../features/form/slice/formApiSlice'

export const FormSelectTemplate = ({ register, children }) => {
  const { data: templates = [], isSuccess } = useGetTemplateSelectionQuery()

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Form Template</Form.Label>
      <Form.Select {...register}>
        {children ? children : <option value="" disabled>Select Form Template</option>}
        {
          isSuccess ? templates
            .map((c, i) =>
              <option value={c.value} key={i}>
                {c.label.toUpperCase()}
              </option>) : null
        }
      </Form.Select>
      <Form.Text className="text-muted">
        {/* Description */}
      </Form.Text>
    </Form.Group>
  )
}