import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: 'general',
  settings: {
    semester: '',
    academicYear: '',
    formId: '',
    pdf: {},
  }
}

const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    handlePreferenceState(state, action) {
      state.settings = action.payload
    },
    handlePreferenceReset(state) {
      state.settings = {
        semester: '',
        academicYear: ''
      }
    }
  }
})

export default preferenceSlice.reducer
export const {
  handlePreferenceState,
  handlePreferenceReset
} = preferenceSlice.actions