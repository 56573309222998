import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const formApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllTemplates: builder.query({
      query: (query) => ({
        url: '/templates',
        method: 'GET',
        params: query
      }),
      providesTags: ['Template']
    }),
    getTemplate: builder.query({
      query: (data) => ({
        url: `/templates/${data.id}`,
        method: 'GET',
        params: data.query
      }),
      providesTags: ['Template']
    }),
    getTemplateSelection: builder.query({
      query: () => ({
        url: '/templates/selection',
        method: 'GET',
      }),
      providesTags: ['Template']
    }),
    getTemplateImportGuide: builder.mutation({
      query: (id) => ({
        url: `/templates/${id}`,
        method: 'GET',
        // params: {
        //   v: 'keys'
        // }
      }),
      providesTags: ['Template']
    }),
    createTemplate: builder.mutation({
      query: (data) => ({
        url: '/templates',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Template']
    }),
    editTemplate: builder.mutation({
      query: (data) => ({
        url: `/templates/${data._id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Template']
    }),
    deleteTemplate: builder.mutation({
      query: (id) => ({
        url: `templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Template']
    })
  }),
  overrideExisting: false
})

export const {
  useGetAllTemplatesQuery,
  useGetTemplateQuery,
  useGetTemplateSelectionQuery,
  useGetTemplateImportGuideMutation,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation,
} = formApiSlice