import React, { useState } from 'react'
import { Badge, Button, Card, Col, Row, Stack, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteTemplateMutation, useGetTemplateQuery } from './slice/formApiSlice'
import moment from 'moment'
import { arabToRoman } from 'roman-numbers'
import { Spinner } from '../../components/spinners/Spinner'
import { ModalDelete } from '../../components/modals/ModalDelete'

const ImportGuide = ({ guide = [] }) => {
  return (
    <Card className="mb-3">
      <Card.Header>
        Import Guide
      </Card.Header>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>Column #</th>
              <th>Required Data</th>
            </tr>
          </thead>
          <tbody>
            {
              guide.map((g, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{g}</td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

const ModalDeleteForm = ({ data = {} }) => {
  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const [deleteForm] = useDeleteTemplateMutation()

  const handleDeleteClicked = async () => {
    try {
      await deleteForm(data._id).unwrap()
      await navigate('/evaluations', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
        disabled={data.total > 0}
      >
        Delete
      </Button>
      <ModalDelete
        show={show}
        setShow={setShow}
        action={handleDeleteClicked}
      >
        <p><strong>{data.name}</strong></p>
      </ModalDelete>
    </>
  )
}

export const ViewFormInfo = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const { data: form = {}, isLoading, isSuccess } = useGetTemplateQuery({ id: id })


  const handleEditClicked = async () => {
    try {
      await navigate(`/evaluations/${id}/edit`)
    } catch (err) {
      console.error(err)
    }
  }

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Row>
      <Col md={8}>
        <Card className="mb-3">
          <Card.Header>
            <Stack direction="horizontal" gap={3}>
              <small>Used in {form.total} evaluations</small>
              <small className="ms-auto">{moment(form.updatedAt).format('YYYY-MM-DD hh:mmA')} - {form.active ? 'Active' : 'Inactive'}</small>
            </Stack>
          </Card.Header>
          <Card.Body>
            <small>{form._id}</small>
            <Card.Title>{form.name}</Card.Title>
            <Card.Subtitle className="mb-3">
              {
                form.category.map((c, i) => <span key={i}><Badge bd="primary" className="text-capitalize">{c}</Badge>&nbsp;</span >)
              }
            </Card.Subtitle>
            <Card.Subtitle className="mb-3">
              {
                form.departments.map((c, i) => <span key={i}><Badge bd="primary" className="text-capitalize">{c}</Badge>&nbsp;</span >)
              }
            </Card.Subtitle>
            {
              form.content.map((t, i) =>
                <div key={i} className="mb-3">
                  <h5>
                    {arabToRoman(i + 1)}. {t.text} {t.multiplier ? `(${t.multiplier}%)` : ''}
                  </h5>
                  <ol>
                    {t.children.map((c, ii) =>
                      <li key={i + '-' + ii}>
                        {c.text}
                      </li>
                    )}
                  </ol>
                </div>
              )
            }
          </Card.Body>
          <Card.Footer>
            <Stack gap={1} direction="horizontal">
              <span className="ms-auto" />
              <ModalDeleteForm data={form} />
              <Button
                onClick={handleEditClicked}
                variant="secondary">
                Edit
              </Button>
            </Stack>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <ImportGuide guide={form.importGuide} />
      </Col>
    </Row>
  )
}
