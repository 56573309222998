import React, { useState } from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import { ModalPopulateData } from './components/ModalPopulateData'

const routes = [
  {
    link: '/departments',
    label: 'Departments'
  },
  {
    link: '/departments/create',
    label: 'Create Department'
  },
]

export const LayoutDepartment = () => {
  return (
    <Container fluid className="mb-3">
      <Nav className="justify-content-start mb-3" variant="pills">
        {
          routes.map((route, index) =>
            <Nav.Item key={index}>
              <Nav.Link as={Link} to={route.link}>
                {route.label}
              </Nav.Link>
            </Nav.Item>
          )
        }
        <PopulateDataModal />
      </Nav>
      <Outlet />
    </Container>
  )
}

const PopulateDataModal = ({ children }) => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Nav.Item>
        <Nav.Link onClick={() => setModalShow(true)}>
          {children ? children : 'Populate Data'}
        </Nav.Link>
      </Nav.Item>

      <ModalPopulateData
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}