import React from 'react'
import { Form } from 'react-bootstrap'

export const FormCheckCategory = ({ register }) => {
  const options = [
    {
      value: 'assistant principal',
      label: 'Assistant Principal'
    },
    {
      value: 'dean',
      label: 'Dean'
    },
    {
      value: 'director',
      label: 'Director'
    },
    {
      value: 'employee',
      label: 'Employee'
    },
    {
      value: 'faculty',
      label: 'Faculty'
    },
    {
      value: 'head',
      label: 'Head'
    },
    {
      value: 'president',
      label: 'President',
    },
    {
      value: 'principal',
      label: 'Principal'
    },
    {
      value: 'program chair',
      label: 'Program Chair'
    },
    {
      value: 'school head',
      label: 'School Head'
    },
    {
      value: 'students',
      label: 'Students'
    },
    {
      value: 'vice president',
      label: 'Vice President',
    },
  ]

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Category</Form.Label>
      <br />
      {
        options
          .map((v, i) =>
            <Form.Check
              key={i}
              inline
              value={v.value}
              label={v.label}
              id={`${register.name}-${v.value}`}
              type="switch"
              {...register}
            />
          )
      }
    </Form.Group>
  )
}