import React, { useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table'

import { Table as BSTable, Pagination, Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import { Spinner } from '../spinners/Spinner'


export const Table = ({ columns, data, setModalShow = null, limit = 10, isFetching, children }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: limit },
  }, useGlobalFilter, useSortBy, usePagination)

  return (
    <>
      <TableSearch setGlobalFilter={setGlobalFilter} />
      {
        isFetching ? <Spinner /> :
          <BSTable
            striped
            bordered
            hover
            responsive
            size="md"
            {...getTableProps()}
            className="shadow-sm"
          >
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i + 100}{...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, ii) => (
                    <th key={ii + 200} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr key={i + 300} {...row.getRowProps()}
                    onClick={() => {
                      if (setModalShow) setModalShow(row.original)
                      // console.log(row.original)
                    }}
                  >
                    {row.cells.map((cell, ii) => {
                      return (
                        <td key={ii + 400} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </BSTable>
      }

      <Row>
        <Col>
          <div className="d-flex flex-row align-items-start justify-content-between">
            {
              children ?
                children :
                <>
                  <div>
                    <Form.Select
                      aria-label="Show Results"
                      value={pageSize}
                      onChange={e => { setPageSize(Number(e.target.value)) }}
                    >
                      {
                        [10, 20, 30, 40, 50, 75, 100]
                          .map(pageSize => (
                            <option key={pageSize} value={pageSize}>Show&nbsp;{pageSize}</option>
                          ))
                      }
                    </Form.Select>
                  </div>
                  <div>
                    <span>
                      {/* Page{' '} */}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{' '}
                    </span>
                  </div>
                  <div>
                    <Pagination>
                      <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                      <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                      <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                      <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
                  </div>
                </>
            }
          </div>
        </Col>
      </Row>
    </>
  )
}

const TableSearch = ({ setGlobalFilter }) => {
  const [filterInput, setFilterInput] = useState('')

  const handleFilterChange = e => {
    const value = e.target.value
    setGlobalFilter(value !== '' ? value : null)
    setFilterInput(value)
  }

  return (
    <Row>
      <Col md={{ span: 6, offset: 6 }}>
        <InputGroup className="my-3">
          <InputGroup.Text>Search</InputGroup.Text>
          <Form.Control
            aria-label="Filter Input"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={'Search entries'}
          />
        </InputGroup>
      </Col>
    </Row>
  )
}
