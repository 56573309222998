import React from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetPreferencesMutation } from '../preference/slice/preferenceApiSlice'
import { useLoginMutation } from './slice/loginApiSlice'

export const Login = () => {
  return (
    <div style={{ height: '100vh', background: '#f0f0f0' }}>
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <img src="/logo512.png" width={'150px'} alt="UB Seal" className="my-5" />
        <LoginForm />
      </div>
    </div>
  )
}

const LoginForm = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { name } = useSelector(state => state.preference)

  const [preference] = useGetPreferencesMutation()
  const [login, { isFetching }] = useLoginMutation()

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: {
      isValid,
      errors
    }
  } = useForm()

  const redirectUrl = searchParams.has('redirectUrl') ? searchParams.get('redirectUrl') : '/evaluations'

  const onLoginSubmit = async (data) => {
    try {
      await login(data).unwrap()
      await preference(name).unwrap()
      await reset()
      await navigate(redirectUrl)
    } catch (err) {
      console.error(err)
      await resetField('password')
    }
  }

  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>Login</Card.Title>
        <Card.Subtitle>Human Resource Information System</Card.Subtitle>

        <Form autoComplete="off" onSubmit={handleSubmit(onLoginSubmit)}>
          <Form.Floating className="my-3">
            <Form.Control
              {...register('username', { required: true })}
              id="username"
              type="text"
              placeholder="Username"
            />
            <label htmlFor="username">Username</label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              {...register('password', { required: true })}
              id="password"
              type="password"
              placeholder="Password"
            />
            <label htmlFor="password">Password</label>
          </Form.Floating>
          <div className="d-grid">
            <Button
              type="submit"
              variant="primary"
              disabled={isFetching || !isValid}
            >
              Login
            </Button>
          </div>
          {/* <div className="d-grid">
            <Button
              type="submit"
              variant="link"
              disabled={isFetching}
            >
              Forgot Password
            </Button>
          </div> */}
        </Form>

      </Card.Body>
    </Card>
  )
}