import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const evaluationApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllEvaluation: builder.query({
      query: (query) => ({
        url: '/evaluations',
        method: 'GET',
        params: Object
          .entries(query)
          .reduce((a, [k, v]) =>
            (v ? (a[k] = v, a) : a), {}
          )
      }),
      providesTags: ['Evaluation']
    }),
    getEvaluationGroups: builder.query({
      query: () => ({
        url: '/evaluations/categories',
        method: 'GET',
      }),
      providesTags: ['Evaluation']
    }),
    getProcessedEvaluation: builder.query({
      query: (data) => ({
        url: `/evaluations/realtime/${data.formId}/${data.group}`,
        method: 'GET',
        params: Object
          .entries(data.query)
          .reduce((a, [k, v]) =>
            (v ? (a[k] = v, a) : a), {}
          )
      }),
      providesTags: ['Evaluation']
    }),
    editEvaluation: builder.mutation({
      query: data => ({
        url: `/evaluations/${data._id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Evaluation']
    }),
    deleteEvaluation: builder.mutation({
      query: id => ({
        url: `/evaluations/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Evaluation']
    }),
    patchEvaluation: builder.mutation({
      query: data => ({
        url: '/evaluations',
        method: 'PATCH',
        params: data.params,
        body: data.body
      }),
      invalidatesTags: ['Evaluation']
    }),
    purgeEvaluation: builder.mutation({
      query: data => ({
        url: '/evaluations',
        method: 'PURGE',
        params: Object
          .entries(data)
          .reduce((a, [k, v]) =>
            (v ? (a[k] = v, a) : a), {}
          )
      }),
      invalidatesTags: ['Evaluation']
    }),
    importRaw: builder.mutation({
      query: (data) => ({
        url: '/evaluations/import',
        method: 'POST',
        body: data.body,
        params: data.params
      }),
      invalidatesTags: ['Evaluation', 'Template']
    }),
    processData: builder.mutation({
      query: (data) => ({
        url: '/evaluations/process',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Evaluation', 'Report']
    })
  }),
  overrideExisting: false
})

export const {
  useGetAllEvaluationQuery,
  useGetEvaluationGroupsQuery,
  useGetProcessedEvaluationQuery,
  useEditEvaluationMutation,
  useDeleteEvaluationMutation,
  usePatchEvaluationMutation,
  usePurgeEvaluationMutation,
  useImportRawMutation,
  useProcessDataMutation,
} = evaluationApiSlice
