import { createSlice } from '@reduxjs/toolkit'
import jwt from 'jwt-decode'

const hasToken = () => {
  const token = sessionStorage.getItem('token')
  if (!token) return null
  return token
}

const initUser = () => {
  const token = sessionStorage.getItem('token')
  if (!token) return {}
  return jwt(token)
}

const initialState = {
  token: hasToken(),
  user: initUser(),
  status: 'idle',
  error: null
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    handleLogin(state, action) {
      state.token = action.payload.token
      state.user = jwt(action.payload.token)
      sessionStorage.setItem('token', action.payload.token)
    },
    handleLogout(state) {
      state.token = null
      state.user = {}
      sessionStorage.removeItem('token')
    },
    handleChangePassword(state, action) {
      state.user.changePassword = action.payload
    }
  }
})

export default loginSlice.reducer
export const { handleLogin, handleLogout, handleChangePassword } = loginSlice.actions