import moment from 'moment'
import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleCloseNotification } from './slice/notificationSlice'

export const Notification = () => {
  const dispatch = useDispatch()

  const { alerts = [] } = useSelector(state => state.notification)

  return (
    <ToastContainer
      className="p-3"
      position="bottom-end"
    >
      {
        alerts.map((a, i) =>
          <Toast
            key={i}
            show={a.show}
            bg={a.variant}
            delay={a.delay}
            autohide
            onClose={() =>
              dispatch(handleCloseNotification(a.id))
            }
          >
            <Toast.Header closeButton>
              <strong className="me-auto">{a.title}</strong>
              <small>{moment(a.timestamp).fromNow()}</small>
            </Toast.Header>
            <Toast.Body>
              {a.message}
            </Toast.Body>
          </Toast>
        )
      }
    </ToastContainer>
  )
}