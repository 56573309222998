import React from 'react'
import { Button, Col, Form, Row, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleEvaluationFilter, handleEvaluationFilterReset } from '../../slice/evaluationSlice'
import { FormSelectAcademicYear } from '../../../../components/forms/selections/FormSelectAcademicYear'
import { FormSelectSemester } from '../../../../components/forms/selections/FormSelectSemester'
import { FormSelectDepartment } from '../../../../components/forms/selections/FormSelectDepartment'
import { useForm } from 'react-hook-form'

export const EvaluationFilter = ({ departments, children }) => {
  const dispatch = useDispatch()
  const { filter } = useSelector(state => state.evaluation)
  const { register } = useForm({
    defaultValues: {
      ...filter
    }
  })

  const handleFilterChange = e => {
    const { id, value } = e.target
    dispatch(handleEvaluationFilter({ key: id, value: value }))
    dispatch(handleEvaluationFilter({ key: 'page', value: 1 }))
  }

  const handleFilterReset = () => dispatch(handleEvaluationFilterReset())

  return (
    <>
      <Row>
        <Col md={3}>
          <FormSelectAcademicYear
            register={{
              ...register('academicYear', {
                onChange: e => handleFilterChange(e)
              })
            }}
          >
            <option value="">All Academic Years</option>
          </FormSelectAcademicYear>
        </Col>
        <Col md={3}>
          <FormSelectSemester
            register={{
              ...register('semester', {
                onChange: e => handleFilterChange(e)
              })
            }}
          >
            <option value="">All Semesters</option>
          </FormSelectSemester>
        </Col>
        <Col md={6}>
          <FormSelectDepartment
            register={{
              ...register('departmentCode', {
                onChange: e => handleFilterChange(e)
              })
            }}
            list={departments}
          >
            <option value="">All Departments</option>
          </FormSelectDepartment>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="section">
            <Form.Label>Section</Form.Label>
            <Form.Control
              placeholder="Section"
              {...register('section', {
                required: false,
                onBlur: e => handleFilterChange(e)
              })}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="subjectCode">
            <Form.Label>Subject Code</Form.Label>
            <Form.Control
              placeholder="Subject Code"
              {...register('subjectCode', {
                required: false,
                onBlur: e => handleFilterChange(e)
              })}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="employeeId">
            <Form.Label>Employee ID</Form.Label>
            <Form.Control
              placeholder="Employee ID"
              type="number"
              {...register('employeeId', {
                required: false,
                onBlur: e => handleFilterChange(e)
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Stack gap={1} direction="horizontal">
          <Button variant="secondary" onClick={handleFilterReset}>Reset</Button>
          <span className="ms-auto" />
          {children}
        </Stack>
      </Row>
    </>
  )
}