import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

export const LayoutPublic = ({ children }) => {
  const token = useSelector(state => state.authentication.token)

  if (token) {
    return <Navigate to={'/'} replace />
  }

  return (
    <>
      {children ? children : <Outlet />}
    </>
  )
}