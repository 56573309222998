import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export const ModalDelete = ({
  action,
  children,
  show,
  setShow
}) => {
  const handleClose = () => setShow(false)
  const handleAction = async () => {
    try {
      await action()
      await handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this data? </p>
        {children}
        <p>
          Please take note that this action is <strong>not reversible</strong>.
          And you are aware of the action you are about to commit
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAction}>
          Yes, I understand
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          No, Go back
        </Button>
      </Modal.Footer>
    </Modal>
  )
}