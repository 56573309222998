import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filter: {
    name: '',
    category: '',
    active: true,
    createdBy: ''
  }
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    handleFormFilter(state, action) {
      state.filter[action.payload.key] = action.payload.value
    },
    handleFormFilterReset(state) {
      state.filter = {
        name: '',
        category: '',
        active: true,
        createdBy: '',
      }
    }
  }
})

export default formSlice.reducer
export const {
  handleFormFilter,
  handleFormFilterReset,
} = formSlice.actions