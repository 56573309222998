import React from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useGetSemesterSelectionQuery } from '../../../features/semesters/slice/semesterApiSlice'

export const FormSelectSemester = ({ register, children, hidden = false }) => {
  const { roles = 'user' } = useSelector(state => state.authentication.user)

  const { data: semesters = [], isSuccess } = useGetSemesterSelectionQuery()

  return (
    <Form.Group className="mb-3" controlId={register.name} hidden={hidden}>
      <Form.Label>Semester</Form.Label>
      {
        roles === 'admin' ?
          <Form.Select {...register}>
            {children ? children : <option value="" disabled>Select Semester</option>}
            {
              isSuccess ? semesters
                .map((c, i) =>
                  <option value={c.value} key={i}>
                    {c.label}
                  </option>) : null
            }
          </Form.Select> :
          <Form.Control {...register} readOnly plaintext />
      }
      <Form.Text className="text-muted">
        {/* Description */}
      </Form.Text>
    </Form.Group>
  )
}