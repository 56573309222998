import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useGetDepartmentSelectionQuery } from '../../../features/department/slice/departmentApiSlice'

export const FormCheckDepartment = ({ register, list = [], multiple = false }) => {
  const { data: departments = [], isSuccess } = useGetDepartmentSelectionQuery()

  const selection = useMemo(() => {
    if (list.length > 0)
      return departments
        .filter(department =>
          list.includes(department.value)
        )
    return departments
  }, [departments])

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Department</Form.Label>
      {/* <Form.Select {...register} multiple={multiple}>
        {children ? children : <option value="" disabled>Select Department</option>}
        {
          isSuccess ? selection
            .map((c, i) =>
              <option value={c.value} key={i}>
                {c.label}
              </option>) : null
        }
      </Form.Select> */}
      <br />
      {
        isSuccess ? selection
          .map((v, i) =>
            <Form.Check
              key={i}
              // inline
              value={v.value}
              label={v.label}
              id={`${register.name}-${v.value}`}
              type="checkbox"
              {...register}
            />
          ) : null
      }
      <Form.Text className="text-muted">
        {/* Description */}
        {multiple ? 'Ctrl + Click to select multiple departments' : null}
      </Form.Text>
    </Form.Group>
  )
}