export const appRoutes = [
  {
    label: 'Home',
    link: '/',
    icon: 'bi-house-door',
    disabled: false,
    badge: false
  },
  // {
  //   label: 'Attendance',
  //   link: '/attendance',
  //   icon: ' bi-clock-history',
  //   disabled: true,
  //   badge: 'planned'
  // },
  // {
  //   label: 'Leaves',
  //   link: '/evaluation',
  //   icon: 'bi-calendar2-event',
  //   disabled: true,
  //   badge: 'planned'
  // },
]

export const featureRoutes = []

export const evaluationRoutes = [
  // {
  //   label: 'Analytics',
  //   link: '/analytics',
  //   icon: 'bi-clipboard-data',
  //   disabled: false,
  //   badge: 'development'
  // },
  {
    label: 'Evaluations',
    link: '/evaluations',
    icon: 'bi-clipboard-data',
    disabled: false,
    badge: false
  },
  // {
  //   label: 'Forms',
  //   link: '/forms',
  //   icon: ' bi-ui-radios',
  //   disabled: false,
  //   badge: 'development'
  // },
  {
    label: 'Imports',
    link: '/imports',
    icon: ' bi-upload',
    disabled: false,
    badge: false
  },
]

export const resourceRoutes = [
  {
    label: 'Departments',
    link: '/departments',
    icon: 'bi-building',
    disabled: false,
    badge: false
  },
  {
    label: 'Employees',
    link: '/employees',
    icon: 'bi-people',
    disabled: false,
    badge: false
  },
]

export const adminRoutes = [
  {
    label: 'Terms',
    link: '/terms',
    icon: 'bi-calendar3',
    disabled: false,
    badge: false
  },
  {
    label: 'Semesters',
    link: '/semesters',
    icon: 'bi-calendar-range',
    disabled: false,
    badge: false
  },
  // {
  //   label: 'Roles',
  //   link: '/roles',
  //   icon: 'bi-person-lines-fill',
  //   disabled: true,
  //   badge: 'development'
  // },
  {
    label: 'System Users',
    link: '/users',
    icon: 'bi-person-badge',
    disabled: false,
    badge: false
  },
]

export const userRoutes = [
  // {
  //   label: 'Account',
  //   link: '/profile',
  //   icon: <i className="bi bi-person-circle" />,
  //   disabled: false,
  //   badge: false
  // },
  {
    label: 'Preferences',
    link: '/preferences',
    icon: 'bi-gear',
    disabled: false,
    badge: false
  },
]