import moment from 'moment'
import React, { useMemo } from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ButtonDeleteConfirm } from '../../components/buttons/ButtonDeleteConfirm'
import { Spinner } from '../../components/spinners/Spinner'
import { Table } from '../../components/tables/Table'
import { useDeleteImportMutation, useGetAllImportLogsQuery } from './slice/importerApiSlice'

export const Importer = () => {
  const { data: logs = [], isLoading, isSuccess } = useGetAllImportLogsQuery()
  const [deleteImport] = useDeleteImportMutation()

  const columns = useMemo(() => [
    {
      Header: 'Academic Year',
      accessor: 'academicYear'
    },
    {
      Header: 'Semester',
      accessor: 'semester'
    },
    {
      Header: 'Department',
      accessor: 'department'
    },
    {
      Header: 'Evaluation Form',
      accessor: 'formName',
      Cell: ({ cell: { value } }) => <span className="text-uppercase">{value}</span>
    },
    {
      Header: 'Total',
      accessor: 'total'
    },
    {
      Header: 'Import Date',
      id: 'importDate',
      accessor: '_id',
      Cell: ({ cell: { value } }) => moment.unix(value).fromNow()
    },
    {
      Header: 'Imported By',
      id: 'importedBy',
      Cell: ({ row: { original: { importedBy } } }) => `${importedBy?.firstName} ${importedBy?.lastName}`
    },
    {
      Header: '',
      id: 'view',
      Cell: ({ row: { original } }) => {
        return (
          <Button
            variant="link"
            className="p-0"
            as={Link}
            to={`/evaluations/${original.formId}/data?importId=${original._id}`}
          >
            <i className="bi bi-eye" />View
          </Button>
        )
      }

    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row: { original } }) => <ButtonDeleteConfirm
        data={original}
        action={() => deleteImport(original._id)}
      >
        <p>
          This action will delete all evaluation records related to&nbsp;
          <strong>
            {original.formName} - {original.academicYear} - {original.semester} - {original.department}
          </strong>
          .&nbsp;The data was imported on <strong>{moment.unix(original._id).format('MMMM DD, YYYY hh:mm:ssA')}</strong>
          &nbsp;by <strong>{original?.importedBy.firstName} {original?.importedBy.lastName}</strong>
        </p>
      </ButtonDeleteConfirm>
    }
  ], [])

  const data = useMemo(() => logs.evaluations, [logs])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Import Logs</Card.Title>
        <Card.Subtitle>All Import Logs</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}