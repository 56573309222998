import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'

export const FormSelectCategory = ({ register, children, list = [] }) => {
  const options = [
    { value: 'department', label: 'Department' },
    { value: 'employee', label: 'Employee' },
    { value: 'subject', label: 'Subject' },
  ]

  const selection = useMemo(() => {
    if (list.length > 0) {
      if (!list.includes('subjectCode')) {
        return options.filter(o => o.value !== 'subject')
      }
    }
    return options
  }, [list])

  const isSuccess = true

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Category</Form.Label>
      <Form.Select {...register}>
        {children ? children : <option value="" disabled>Select Category</option>}
        {
          isSuccess ? selection
            .map((c, i) =>
              <option value={c.value} key={i}>
                {c.label}
              </option>) : null
        }
      </Form.Select>
      <Form.Text className="text-muted">
        {/* Description */}
      </Form.Text>
    </Form.Group>
  )
}