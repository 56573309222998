import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filter: {
    departmentCode: '',
    academicYear: '',
    semester: '',
    evaluator: '',
  },
  departments: [],
  category: 'employee'
}

const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    handleAnalyticCategory(state, action) {
      state.category = action.payload
    },
    handleAnalyticDepartments(state, action) {
      state.departments.push(action.payload)
    },
    handleAnalyticFilter(state, action) {
      state.filter[action.payload.key] = action.payload.value
    },
    handleAnalyticFilterReset(state) {
      state.filter = {
        departmentCode: '',
        academicYear: '',
        semester: '',
        evaluator: '',
      }

      state.category = 'employee'

      state.departments = []
    }
  }
})

export default analyticSlice.reducer
export const {
  handleAnalyticCategory,
  handleAnalyticDepartments,
  handleAnalyticFilter,
  handleAnalyticFilterReset,
} = analyticSlice.actions