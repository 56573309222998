import React from 'react'
import { Button, Card, Col, Form, Row, InputGroup, Dropdown, DropdownButton, Stack, FormCheck } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateTemplateMutation, useEditTemplateMutation, useGetTemplateQuery } from './slice/formApiSlice'
import ntol from 'number-to-letter'
import { Spinner } from '../../components/spinners/Spinner'
import { FormSelectDepartment } from '../../components/forms/selections/FormSelectDepartment'
import { FormCheckRequirements } from '../../components/forms/checkbox/FormCheckRequirements'
import { FormCheckCategory } from '../../components/forms/checkbox/FormCheckCategory'
import { FormCheckDepartment } from '../../components/forms/checkbox/FormCheckDepartment'

export const ViewCreateForm = () => {
  const { id } = useParams()

  if (id) return <Edit formId={id} />

  return <FormTemplate />
}

const Edit = ({ formId }) => {
  const { data: form = {}, isLoading, isSuccess } = useGetTemplateQuery({ id: formId })

  if (isLoading) return <Spinner />
  if (isSuccess) return <FormTemplate defaultValue={form} />
}

const FormTemplate = ({ defaultValue = {} }) => {
  const navigate = useNavigate()

  const isEditable = defaultValue.total > 0

  const { register, handleSubmit, reset, control, formState: { isValid, isDirty, errors } } = useForm({
    defaultValues: defaultValue
  })

  const [createForm] = useCreateTemplateMutation()
  const [editForm] = useEditTemplateMutation()

  const handleSubmitClicked = async (data) => {
    try {

      console.log(data)

      if (data._id) {
        await editForm(data).unwrap()
        await navigate(`/evaluations/${data._id}`, { replace: true })
      } else {
        await createForm(data).unwrap()
        await navigate('/evaluations', { replace: true })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Card className="mb-3">
      <Form autoComplete="off" onSubmit={handleSubmit(handleSubmitClicked)}>
        <Card.Body>
          <Card.Title>{defaultValue._id ? 'Edit' : 'Create New'}&nbsp;Form</Card.Title>
          <Row>
            <Col md={10}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Form Name"
                  {...register('name', { required: true })}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3" controlId="active">
                <Form.Label>Status</Form.Label>
                <Form.Select {...register('active', { required: true })} >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormCheckCategory
                register={{ ...register('category', { required: true }) }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormCheckRequirements
                register={{ ...register('requirements', { required: true }) }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormCheckDepartment
                register={{ ...register('departments') }}
              />
            </Col>
          </Row>
          <CategoryForm control={control} register={register} isEditable={isEditable} />
        </Card.Body>
        <Card.Footer>
          <Button
            type="submit"
            disabled={!isValid || !isDirty}
          >
            {defaultValue._id ? 'Edit' : 'Create New'}&nbsp;Form
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}

const CategoryForm = ({ register, control, isEditable }) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'content',
    rules: {
      required: true
    }
  })

  return (
    <>
      {
        fields.map((item, i) =>
          <Card key={item.id} className="mb-3">
            <Card.Body>
              <Stack gap={1} direction="horizontal">
                <h5>Category {ntol(i)}</h5>
                <span className="ms-auto" />
                <Button
                  hidden={i === 0}
                  onClick={() => move(i, i - 1)}
                  variant="link"
                  disabled={isEditable}
                >
                  <i className="bi bi-arrow-up" />
                </Button>
                <Button
                  hidden={i === fields.length - 1}
                  onClick={() => move(i, i + 1)}
                  variant="link"
                  disabled={isEditable}
                >
                  <i className="bi bi-arrow-down" />
                </Button>
                <Button
                  onClick={() => remove(i)}
                  variant="link"
                  disabled={isEditable}
                >
                  <i className="bi bi-x-lg" />
                </Button>
              </Stack>
              <Row>
                <Col md={10}>
                  <Form.Control
                    {...register(`content.${i}.accessor`, { required: true })}
                    readOnly={true}
                    type="hidden"
                    defaultValue={ntol(i)}
                  />
                  <Form.Group
                    className="mb-3"
                    controlId={`content.${i}.text`}
                  >
                    <Form.Label>Category Title</Form.Label>
                    <Form.Control
                      placeholder="Category Title"
                      {...register(`content.${i}.text`, {
                        required: true,
                        minLength: 5
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group
                    className="mb-3"
                    controlId={`content.${i}.multiplier`}
                  >
                    <Form.Label>Percentage</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="50"
                        type="number"
                        {...register(`content.${i}.multiplier`, {
                          required: true,
                          min: 1,
                          max: 100,
                          valueAsNumber: true,
                        })}
                      />
                      <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <QuestionForm register={register} control={control} parent={i} isEditable={isEditable} />
            </Card.Body>
          </Card>
        )
      }
      <Button onClick={() => append()} variant="link" disabled={isEditable}><i className="bi bi-plus-lg" />Add Category</Button>
    </>
  )
}

const QuestionForm = ({ register, control, parent, isEditable }) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `content.${parent}.children`
  })

  return (
    <>
      <h6>Questions</h6>
      {
        fields.map((item, i) =>
          <div key={item.id}>
            <Form.Control
              {...register(`content.${parent}.children.${i}.accessor`, { required: true })}
              readOnly={true}
              type="hidden"
              defaultValue={(i + 1)}
            />
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">{ntol(parent)}{i + 1}.</InputGroup.Text>
              <Form.Control
                {...register(`content.${parent}.children.${i}.text`, { required: true })}
                as="textarea"
                rows={2}
                placeholder={`Question ${i + 1}`}
                aria-label={`Question ${i + 1}`}
                aria-describedby={`question-${i + 1}-input`}
              />

              <Button
                variant="link"
                hidden={i === 0}
                onClick={() => move(i, i - 1)}
                disabled={isEditable}
              >
                <i className="bi bi-arrow-up" />
              </Button>
              <Button
                variant="link"
                hidden={i === fields.length - 1}
                onClick={() => move(i, i + 1)}
                disabled={isEditable}
              >
                <i className="bi bi-arrow-down" />
              </Button>
              <Button
                variant="link"
                onClick={() => remove(i)}
                disabled={isEditable}
              >
                <i className="bi bi-x-lg" />
              </Button>
            </InputGroup>
          </div>
        )
      }
      <Button onClick={() => append()} variant="link" disabled={isEditable}><i className="bi bi-plus-lg" />Add Question</Button>
    </>
  )
}