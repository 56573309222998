import React from 'react'

export const Error404 = () => {
  return (
    <div style={{ height: '80vh', background: 'white', zIndex: 1000 }}>
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <h1>404</h1>
        <h6>Page Not Found</h6>
      </div>
    </div>
  )
}