import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filter: {
    employeeId: '',
    subjectCode: '',
    section: '',
    departmentCode: '',
    academicYear: '',
    semester: '',
    importId: '',
    limit: 50,
    page: 1
  }
}

const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    handleEvaluationFilter(state, action) {
      state.filter[action.payload.key] = action.payload.value
    },
    handleEvaluationFilterReset(state) {
      state.filter = {
        employeeId: '',
        subjectCode: '',
        section: '',
        departmentCode: '',
        academicYear: '',
        semester: '',
        limit: 10,
        page: 1
      }
    }
  }
})

export default evaluationSlice.reducer
export const {
  handleEvaluationFilter,
  handleEvaluationFilterReset,
} = evaluationSlice.actions