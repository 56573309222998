import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { handleLogout } from '../../../features/login/slice/loginSlice'
import { handlePreferenceReset } from '../../../features/preference/slice/preferenceSlice'

export const ModalLogout = ({ show, setShow, children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClose = () => setShow(false)

  const logoutHandler = async () => {
    await dispatch(handleLogout())
    await dispatch(handlePreferenceReset())
    await setShow(false)
    await navigate('/login', { replace: true })
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to logout?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        By clicking the <strong>Logout</strong> button,
        you will be redirected to the login page.
        You will need to login again to access your account and other functionalities.
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={logoutHandler}>
          Logout
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}