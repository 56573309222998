import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { usePopulateEmployeeDataMutation } from '../slice/employeeApiSlice'

export const ModalPopulateData = (props) => {
  const [populateData, { isLoading }] = usePopulateEmployeeDataMutation()

  const handleSubmitClicked = async () => {
    try {
      await populateData().unwrap()
      await props.onHide()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="process-data-modal"
      centered
    >
      <Modal.Header>
        Populate Employees
      </Modal.Header>

      <Modal.Body>
        This process will sync the employee data from the AMS database.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmitClicked} disabled={isLoading}>{isLoading ? 'Please wait...' : 'Populate Data'}</Button>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}