import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'
import { handleAddNotification } from '../../../components/notifications/slice/notificationSlice'
import { handlePreferenceState } from './preferenceSlice'

export const preferenceApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getPreferences: builder.mutation({
      query: (id) => ({
        url: `/preferences/${id}`,
        method: 'GET'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(handlePreferenceState(data))
        } catch (err) {
          console.error(err)
        }
      },
      providesTags: ['Preference']
    }),
    getSettings: builder.query({
      query: (id) => ({
        url: `/preferences/${id}`,
        method: 'GET'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(handlePreferenceState(data))
        } catch (err) {
          console.error(err)
        }
      },
      providesTags: ['Preference']
    }),
    updatePreference: builder.mutation({
      query: (data) => ({
        url: `/preferences/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(handleAddNotification('Preferences', 'Processing Changes', 1000, 'light'))

        try {
          const { data } = await queryFulfilled

          dispatch(handlePreferenceState(data))
          dispatch(handleAddNotification('Preferences', 'Changes Saved Successfully', 3000, 'success'))
        } catch (err) {
          console.error(err)
          dispatch(handleAddNotification('Preferences', 'Failed to Save Changes', 3000, 'warning'))
        }
      },
      invalidatesTags: ['Preference']
    })
  }),
  overrideExisting: false
})

export const {
  useGetPreferencesMutation,
  useGetSettingsQuery,
  useUpdatePreferenceMutation,
} = preferenceApiSlice