import { configureStore } from '@reduxjs/toolkit'

import loginReducer from '../features/login/slice/loginSlice'
import analyticReducer from '../features/analytics/slice/analyticSlice'
import formReducer from '../features/form/slice/formSlice'
import navigationReducer from '../components/navigations/slice/navigationSlice'
import notificationReducer from '../components/notifications/slice/notificationSlice'
import evaluationReducer from '../features/evaluation/slice/evaluationSlice'
import preferenceReducer from '../features/preference/slice/preferenceSlice'
import { hrisApiSlice } from './slice/hrisApiSlice'

export const store = configureStore({
  reducer: {
    authentication: loginReducer,
    analytic: analyticReducer,
    form: formReducer,
    navigation: navigationReducer,
    notification: notificationReducer,
    evaluation: evaluationReducer,
    preference: preferenceReducer,
    [hrisApiSlice.reducerPath]: hrisApiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(hrisApiSlice.middleware)
})
