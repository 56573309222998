import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Navbar } from '../navigations/Navbar'
import { Offcanvas } from '../navigations/Offcanvas'
import { handleOffcanvasToggle } from '../navigations/slice/navigationSlice'
import { useGetSettingsQuery } from '../../features/preference/slice/preferenceApiSlice'

export const LayoutProtected = ({ children }) => {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.authentication)
  const { name } = useSelector(state => state.preference)
  const { offcanvas, offcanvasWidth } = useSelector(state => state.navigation)
  const { pathname: location } = useLocation()

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    if (isDesktopOrLaptop) dispatch(handleOffcanvasToggle(true))
    if (isTabletOrMobile) dispatch(handleOffcanvasToggle(false))
  }, [isTabletOrMobile, isDesktopOrLaptop])

  // useGetPreferencesQuery(name)
  useGetSettingsQuery(name)

  if (!token) return <Navigate to={`/login?redirectUrl=${location}`} replace />

  return (
    <>
      <Navbar />
      <Offcanvas />
      <div
        style={{
          marginLeft: offcanvas ? offcanvasWidth : null,
          transition: 'margin-left .3s',
          transitionTimingFunction: 'ease'
        }}
      >
        {children ? children : <Outlet />}
      </div>
    </>
  )
}