import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Outlet, Link } from 'react-router-dom'

export const LayoutFeature = ({ children, routes = [] }) => {
  return (
    <Container fluid className="mb-3">
      <Nav className="justify-content-start mb-3" variant="pills">
        {
          routes.map((route, index) =>
            <Nav.Item key={index}>
              <Nav.Link as={Link} to={route.link}>
                {route.label}
              </Nav.Link>
            </Nav.Item>
          )
        }
      </Nav>
      {children ? children : <Outlet />}
    </Container>
  )
}