import React, { useMemo, useState } from 'react'
import moment from 'moment'
import csvtojson from 'csvtojson'
import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormControlFile } from '../../components/forms/controls/FormControlFile'
import { FormSelectAcademicYear } from '../../components/forms/selections/FormSelectAcademicYear'
import { FormSelectDepartment } from '../../components/forms/selections/FormSelectDepartment'
import { FormSelectEvaluator } from '../../components/forms/selections/FormSelectEvaluator'
import { FormSelectSemester } from '../../components/forms/selections/FormSelectSemester'
import { FormSelectTemplate } from '../../components/forms/selections/FormSelectTemplate'
import { Table } from '../../components/tables/Table'
import { useGetTemplateImportGuideMutation } from '../form/slice/formApiSlice'
import { useImportEvaluationMutation } from './slice/importerApiSlice'

const allowedExtensions = ['csv']

export const ImportEvaluation = () => {
  const navigate = useNavigate()
  const { user } = useSelector(state => state.authentication)

  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [error, setError] = useState('')
  const [file, setFile] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    getFieldState,
    formState
  } = useForm({
    defaultValues: {
      formId: '',
      departmentCode: '',
      evaluator: '',
      semester: '',
      academicYear: '',
      importId: moment().unix(),
      userId: user.name
    },
  })

  const { isValid, isDirty, dirtyFields, errors } = formState

  const [getImportGuide, { data: template = {}, isSuccess: isImportGuideSuccess }] = useGetTemplateImportGuideMutation()
  const [importData, { isLoading }] = useImportEvaluationMutation()

  const handleSubmitClicked = async (data) => {
    try {
      const formData = new FormData()

      for (const key in data) {
        if (key === 'file') {
          formData.append(key, data[key][0])
        }
        formData.append(key, data[key])
      }

      await importData({ body: formData, params: { override: data.override } }).unwrap()
      // await navigate(`/evaluations/${data.formId}`, { replace: true })
      await navigate('/imports', { replace: true })
      await reset()

      // await reset()
    } catch (err) {
      console.error(err)
    }
  }

  const handleFileReset = () => {
    reset()
    setData([])
    setColumns([])
    setFile('')
  }

  const handleTemplateChange = async (e) => {
    try {
      await getImportGuide(e.target.value)
      resetField('departmentCode')
      resetField('evaluator')
      resetField('file')
      resetField('academicYear')
      resetField('semester')
    } catch (err) {
      console.error(err)
    }
  }

  const handleFileChange = e => {
    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0]

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1]
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file')
        return
      }

      // If input type is correct set the state
      setFile(inputFile)
    }
  }

  const handleFilePreview = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError('Enter a valid file')

    if (isImportGuideSuccess) {
      setColumns(template.importGuide)
    }

    const reader = new FileReader()

    reader.onload = async ({ target }) => {
      const csv = await csvtojson({
        trim: true,
        headers: template.importGuide
      })
        .fromString(target.result)

      setData(csv)
    }

    reader.readAsText(file)
  }

  return (
    <>
      <Form
        encType="multipart/form-data"
        autoComplete="off"
        onSubmit={handleSubmit(handleSubmitClicked)}
      >
        <Card>
          <Card.Body>
            <Card.Title>Import Evaluation</Card.Title>
            <Row>
              <Col md={12}>
                <FormSelectTemplate
                  register={{
                    ...register('formId', {
                      required: true,
                      onChange: handleTemplateChange
                    })
                  }}
                />
              </Col>
            </Row>
            {
              isImportGuideSuccess ? <Row>
                <Col md={9}>
                  <FormSelectDepartment
                    list={template.departments}
                    register={{
                      ...register('departmentCode', {
                        required: true,
                        disabled: !dirtyFields['formId']
                      })
                    }}
                  />
                </Col>
                <Col md={3}>
                  <FormSelectEvaluator
                    list={template.category}
                    register={{
                      ...register('evaluator', {
                        required: true,
                        disabled: !dirtyFields['departmentCode']
                      })
                    }}
                  />
                </Col>
              </Row> : null
            }
            <Row>
              <Col md={12}>
                <FormControlFile
                  hidden={!dirtyFields['evaluator']}
                  register={{
                    ...register('file', {
                      required: true,
                      onChange: handleFileChange,
                      disabled: !dirtyFields['evaluator']
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormSelectAcademicYear
                  hidden={!dirtyFields['file']}
                  register={{
                    ...register('academicYear', {
                      required: true,
                      disabled: !dirtyFields['file']
                    })
                  }}
                />
              </Col>
              <Col md={4}>
                <FormSelectSemester
                  hidden={!dirtyFields['academicYear']}
                  register={{
                    ...register('semester', {
                      required: true,
                      disabled: !dirtyFields['academicYear']
                    })
                  }}
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Stack direction="horizontal" gap="1">
              <Button
                onClick={handleFilePreview}
                variant="secondary"
                disabled={!isImportGuideSuccess && !file}
              >
                Preview
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="ms-auto"
                disabled={isLoading || !isValid}
              >
                {isLoading ? 'Importing...' : 'Import Data'}
              </Button>
              <Button
                onClick={handleFileReset}
                variant="secondary"
                disabled={!isDirty}
              >
                Reset
              </Button>
            </Stack>
          </Card.Footer>
        </Card>
      </Form>
      <FilePreview columns={columns} data={data} />
    </>
  )
}

const FilePreview = ({ columns = [], data }) => {
  const tempColumns = useMemo(() => {
    return columns.map(c => {
      return {
        Header: c,
        accessor: c
      }
    })
  }, [columns])

  const tempData = useMemo(() => data, [data])

  if (tempData.length > 0) return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>Preview Data</Card.Title>
        <Card.Subtitle>This is only a preview of the data that will be imported to the system.</Card.Subtitle>
        <Table columns={tempColumns} data={tempData} />
      </Card.Body>
    </Card>
  )

  return null
}