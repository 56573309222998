import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'
import { handleAddNotification } from '../../../components/notifications/slice/notificationSlice'

export const loginApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: '/me/change-password',
        method: 'POST',
        body: credentials
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Change Password', 'Change Password Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Change Password', 'Change Password Successful', 3000, 'success'))
        } catch (err) {
          // `onError` side-effect
          console.error('slice', err.error)
          dispatch(handleAddNotification('Change Password', 'Change Password Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: []
    }),
  }),
  overrideExisting: false
})

export const {
  useChangePasswordMutation
} = loginApiSlice