import React from 'react'
import { Button } from 'react-bootstrap'
import { downloadFile } from '../libs/downloadFile'
import moment from 'moment'

export const ButtonDownloadCSV = ({ className, data: reports = [] }) => {

  const flattenJSON = (obj = {}, res = {}, extraKey = '') => {
    for (const key in obj) {
      if (typeof obj[key] !== 'object') {
        res[extraKey + key] = obj[key]
      } else {
        flattenJSON(obj[key], res, `${extraKey}${key}.`)
      }
    }
    return res
  }

  const onButtonClicked = async () => {
    try {
      const header = () => {
        let h = []
        for (const key in reports.map(x => flattenJSON(x))[0]) {
          h.push(key)
        }
        return h.join(',')
      }

      const data = reports
        .map(x => flattenJSON(x))
        .reduce((d, e) => {
          let tmp = []
          for (const key in e) {
            tmp.push(e[key])
          }
          d.push(tmp.join(','))
          return d
        }, [])

      downloadFile({
        data: [header(), ...data].join('\n'),
        fileName: `Evaluation_Reports_${moment().format('YYYYMMDDHHmmss')}.csv`,
        fileType: 'text/csv',
      })

    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Button
      className={className}
      variant="primary"
      onClick={onButtonClicked}
    >
      <i className="bi bi-filetype-csv" />&nbsp;
      Download CSV
    </Button>
  )
}

