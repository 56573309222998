import React, { useMemo } from 'react'
import { useGetAllUserQuery } from './slice/userApiSlice'
import { Table } from '../../components/tables/Table'
import { Card } from 'react-bootstrap'
import { Spinner } from '../../components/spinners/Spinner'
import { Link } from 'react-router-dom'
import moment from 'moment'

export const ViewUser = () => {
  const { data: users = [], isLoading, isSuccess } = useGetAllUserQuery()

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: '_id',
      Cell: ({ cell: { value } }) => <Link to={`/users/edit/${value}`}>View</Link>
    },
    {
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Roles',
      accessor: 'roles',
      Cell: ({ cell: { value } }) => <span className="text-capitalize">{value}</span>
    },
    {
      Header: 'Change Password',
      accessor: 'changePassword',
      Cell: ({ cell: { value } }) => value ? 'Required' : 'Not Required'
    },
    {
      Header: 'Last Logged In',
      accessor: 'loginAt',
      Cell: ({ cell: { value } }) => value ? moment(value).fromNow() : 'Never logged in'
    },
    {
      Header: 'Status',
      accessor: 'active',
      id: 'active',
      Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    },
    // {
    //   Header: () => <div className="text-end">View</div>,
    //   accessor: (properties) => [properties.active, properties._id],
    //   id: 'actions',
    //   Cell: ({ cell: { value } }) => {
    //     const [active, userId] = value
    //     return (<div className="text-end">
    //       <UserDropdown userId={userId} active={active} />
    //       {/* <Link to={`/users/${value}`}>View User</Link> */}
    //     </div>)
    //   }
    // }
  ], [])

  const data = useMemo(() => users.users, [users])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card>
      <Card.Body>
        <Card.Title>Users</Card.Title>
        <Card.Subtitle>All Users</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}