import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'
import { handleAddNotification } from '../../../components/notifications/slice/notificationSlice'

export const employeeApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllEmployee: builder.query({
      query: () => '/employees',
      providesTags: ['Employee']
    }),
    getEmployeeSelection: builder.query({
      query: () => ({
        url: '/employees/selection',
        method: 'GET',
      }),
      providesTags: ['Employee']
    }),
    getEmployee: builder.query({
      query: (id) => `/employees/${id}`,
      providesTags: ['Employee'],
    }),
    populateEmployeeData: builder.mutation({
      query: () => ({
        url: '/employees/populate',
        method: 'POST',
        params: {
          override: true
        }
      }),
      invalidatesTags: ['Employee']
    }),
    createEmployee: builder.mutation({
      query: (data) => ({
        url: '/employees',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Create Employee', 'Create Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Create Employee', 'Create Successful', 5000, 'success'))
        } catch (err) {
          // `onError` side-effect
          dispatch(handleAddNotification('Create Employee', 'Create Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: ['Employee']
    }),
    updateEmployee: builder.mutation({
      query: (data) => ({
        url: `/employees/${data._id}`,
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Update Employee', 'Update Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Update Employee', 'Update Successful', 5000, 'success'))
        } catch (err) {
          // `onError` side-effect
          dispatch(handleAddNotification('Update Employee', 'Update Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: ['Employee']
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `/employees/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Delete Employee', 'Delete Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleAddNotification('Delete Employee', 'Delete Successful', 5000, 'success'))
        } catch (err) {
          // `onError` side-effect
          dispatch(handleAddNotification('Delete Employee', 'Delete Failed', 3000, 'danger'))
        }
      },
      invalidatesTags: ['Employee']
    }),
  }),
  overrideExisting: false
})

export const {
  useGetAllEmployeeQuery,
  useGetEmployeeQuery,
  useGetEmployeeSelectionQuery,
  usePopulateEmployeeDataMutation,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeeApiSlice