import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const userApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllUser: builder.query({
      query: () => '/users',
      providesTags: ['User']
    }),
    getUserCount: builder.query({
      query: () => '/users/count',
      providesTags: ['User']
    }),
    getUser: builder.query({
      query: userId => `/users/${userId}`,
      providesTags: ['User']
    }),
    getUserInfo: builder.mutation({
      query: userId => ({
        url: `/users/${userId}`,
        method: 'GET',
      }),
      providesTags: ['User']
    }),
    createNewUser: builder.mutation({
      query: userData => ({
        url: '/users',
        method: 'POST',
        body: userData
      }),
      invalidatesTags: ['User', 'Employee']
    }),
    editUser: builder.mutation({
      query: userData => ({
        url: `/users/${userData._id}`,
        method: 'PUT',
        body: userData
      }),
      invalidatesTags: ['User', 'Employee']
    }),
    deleteUser: builder.mutation({
      query: userId => ({
        url: `/users/${userId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['User', 'Employee']
    }),
  }),
  overrideExisting: false
})

export const {
  useGetAllUserQuery,
  useGetUserCountQuery,
  useGetUserQuery,
  useGetUserInfoMutation,
  useCreateNewUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
} = userApiSlice