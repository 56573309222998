import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FormSelectAcademicYear } from '../../components/forms/selections/FormSelectAcademicYear'
import { FormSelectSemester } from '../../components/forms/selections/FormSelectSemester'
import { FormSelectTemplate } from '../../components/forms/selections/FormSelectTemplate'
import { useGetSettingsQuery, useUpdatePreferenceMutation } from './slice/preferenceApiSlice'

export const ViewPreference = () => {
  const { name } = useSelector(state => state.preference)

  const { data: settings = {} } = useGetSettingsQuery(name)

  const { register } = useForm()

  const [editPreference, { isLoading, isSuccess }] = useUpdatePreferenceMutation()

  const onPreferenceChange = async (e) => {
    try {
      const { id, value, type, checked } = e.target

      if (value !== settings[id])
        await editPreference({ id: settings._id, body: { [id]: value } }).unwrap()

      if (type === 'checkbox')
        await editPreference({ id: settings._id, body: { [id]: checked } }).unwrap()

    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={3}>
            <Card.Title>General Settings</Card.Title>
          </Col>
          <Col md={9}>
            <FormSelectAcademicYear
              register={{
                ...register('academicYear', {
                  value: settings.academicYear,
                  onChange: e => onPreferenceChange(e)
                })
              }}
            />
            <FormSelectSemester
              register={{
                ...register('semester', {
                  value: settings.semester,
                  onChange: e => onPreferenceChange(e)
                })
              }}
            />
            {/* <FormSelectTemplate
              register={{
                ...register('formId', {
                  value: settings.formId,
                  onChange: e => onPreferenceChange(e)
                })
              }}
            /> */}
          </Col>
        </Row>

        {/* <Row>
          <Col md={3}>
            <Card.Title>PDF Settings</Card.Title>
          </Col>
          <Col md={9}>
            <Form.Group controlId="pdf.password" className="mb-3">
              <Form.Label>PDF Password</Form.Label>
              <Form.Control
                type="text"
                onBlur={e => onPreferenceChange(e)}
                defaultValue={settings.pdf.password}
              />
            </Form.Group>
            <Form.Label>Permissions</Form.Label>
            <Form.Check
              className="mb-3"
              id="pdf.modifying"
              type="switch"
              label="Modifying"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.modifying}
            />
            <Form.Check
              className="mb-3"
              id="pdf.copying"
              type="switch"
              label="Copying"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.copying}
            />
            <Form.Check
              className="mb-3"
              id="pdf.annotating"
              type="switch"
              label="Annotating"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.annotating}
            />
            <Form.Check
              className="mb-3"
              id="pdf.fillingForms"
              type="switch"
              label="Filling Forms"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.fillingForms}
            />
            <Form.Check
              className="mb-3"
              id="pdf.contentAccessibility"
              type="switch"
              label="Content Accessibility"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.contentAccessibility}
            />
            <Form.Check
              className="mb-3"
              id="pdf.documentAssembly"
              type="switch"
              label="Document Assembly"
              onChange={e => onPreferenceChange(e)}
              checked={settings.pdf.documentAssembly}
            />
          </Col>
        </Row> */}

      </Card.Body>
      <Card.Footer>
        <small className="ms-auto">
          {
            isLoading ?
              'Saving changes' :
              isSuccess ?
                'Changes Saved' :
                'No changes'
          }
        </small>
      </Card.Footer>
    </Card>
  )
}