import React, { useState } from 'react'
import { Offcanvas as BSOffcanvas, ListGroup, Badge } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { handleOffcanvasToggle } from './slice/navigationSlice'

import { ModalLogout } from './components/ModalLogout'
import { ModalChangePassword } from './components/ModalChangePassword'
import { evaluationRoutes, resourceRoutes } from '../../routes'

export const Offcanvas = () => {
  const dispatch = useDispatch()

  const {
    offcanvas,
    offcanvasWidth,
    routes: { adminRoutes, userRoutes, appRoutes, featureRoutes }
  } = useSelector(state => state.navigation)

  const toggleOffcanvas = () => dispatch(handleOffcanvasToggle(!offcanvas))

  return (
    <BSOffcanvas
      id="offcanvas-navbar"
      aria-labelledby="offcanvas-navbar"
      placement="start"
      backdrop={false}
      scroll={true}
      show={offcanvas}
      style={{
        width: offcanvasWidth
      }}
    >
      <BSOffcanvas.Header
        closeButton={true}
        closeLabel={'Close'}
        onHide={toggleOffcanvas}
      >
        <BSOffcanvas.Title id="offcanvas-navbar">
          <img src={'/banner-dark.png'}
            height="30"
            className="d-inline-block align-top"
            alt={'HRIS'}
          />
        </BSOffcanvas.Title>
      </BSOffcanvas.Header>
      <BSOffcanvas.Body className="px-0">
        <div className="h-100 d-flex flex-column justify-content-between">
          <div>
            <OffcanvasList routes={appRoutes} className="mb-3" childPosition='start'>
              <UserInfo />
            </OffcanvasList>
            <OffcanvasList routes={featureRoutes} className="mb-3" />
            <OffcanvasList title="Performance Evaluation" routes={evaluationRoutes} />
            <OffcanvasList title="Resources" routes={resourceRoutes} />
            <OffcanvasList title="System Administration" routes={adminRoutes} />
          </div>
          <OffcanvasList routes={userRoutes} className="mb-0">
            <OffcanvasChangePassword />
            <OffcanvasLogout />
          </OffcanvasList>
        </div>
      </BSOffcanvas.Body>
    </BSOffcanvas>
  )
}

const OffcanvasList = ({
  routes = [],
  children,
  title = false,
  className = 'mb-3',
  childPosition = 'end'
}) => {

  return (
    <ListGroup variant="flush" className={className}>
      {childPosition === 'start' ? children : null}
      {
        title ?
          <ListGroup.Item><h6>{title}</h6></ListGroup.Item> :
          null
      }
      {
        routes.map((route, i) =>
          <ListGroup.Item
            action
            key={i}
            as={NavLink}
            to={route.link}
            disabled={route.disabled}
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              {/* {route.icon}&nbsp;{route.label} */}

              {
                route.icon ?
                  <i className={`bi ${route.icon}`} /> :
                  null
              }&nbsp;{route.label}
            </div>
            {route.badge ?
              <Badge>{route.badge}</Badge> :
              null
            }
          </ListGroup.Item>
        )
      }
      {childPosition === 'end' ? children : null}
    </ListGroup>
  )
}

const UserInfo = () => {

  const { user: { name, roles } } = useSelector(state => state.authentication)

  const fullName = name
  const role = roles

  const avatarImg = `
  https://ui-avatars.com/api/
  ?name=${fullName}
  &background=d90000
  &color=fff
  &bold=true
  &size=100
  `

  return (
    <ListGroup.Item
      // action={true}
      // as={NavLink}
      // to={'/profile'}
      className="d-flex justify-content-between align-items-center"
    >
      <div>
        <img src={avatarImg} alt="user-avatar" className="img-fluid" />
      </div>
      <div className="me-auto ms-2">
        <h5>{fullName}</h5>
        <h6 className="text-capitalize">{role}</h6>
      </div>
    </ListGroup.Item>
  )
}

const OffcanvasChangePassword = () => {
  const [show, setShow] = useState(false)

  return (
    <>
      <ListGroup.Item onClick={() => setShow(true)} action>
        <i className="bi bi-key" />&nbsp;Change Password
      </ListGroup.Item>
      <ModalChangePassword show={show} setShow={setShow} />
    </>
  )
}

const OffcanvasLogout = () => {
  const [show, setShow] = useState(false)
  return (
    <>
      <ListGroup.Item onClick={() => setShow(true)} action>
        <i className="bi bi-box-arrow-right" />&nbsp;Logout
      </ListGroup.Item>
      <ModalLogout show={show} setShow={setShow} />
    </>
  )
}

