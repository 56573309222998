import React from 'react'
import {
  Container,
  // Nav,
  Navbar as BSNavbar
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleOffcanvasToggle } from './slice/navigationSlice'

export const Navbar = () => {
  const dispatch = useDispatch()

  const { offcanvas, offcanvasWidth } = useSelector(state => state.navigation)

  return (
    <BSNavbar
      bg="light"
      expand={true}
      sticky="top"
      className="mb-3 shadow-sm pe-3"
      style={{
        marginLeft: offcanvas ? offcanvasWidth : null,
        transition: 'margin-left .3s',
        transitionTimingFunction: 'ease',
        minHeight: '60px'
      }}
    >
      <Container fluid>
        {
          !offcanvas ?
            <BSNavbar.Brand onClick={() => dispatch(handleOffcanvasToggle(!offcanvas))}>
              <img
                src={'/banner-dark.png'}
                height="30"
                className="d-inline-block align-top"
                alt={'HRIS'}
                style={{
                  opacity: offcanvas ? '0%' : '100%',
                  transition: 'transition 1s',
                  transitionTimingFunction: 'ease',
                }}
              />
            </BSNavbar.Brand> :
            null
        }

        <BSNavbar.Toggle aria-controls="main-navbar" />
        <BSNavbar.Collapse id="main-navbar" className="me-auto justify-content-between">
          <div></div>
          {/* <Nav>
            <Nav.Link onClick={() => dispatch(handleModal({ modal: 'logout', show: true }))}>Logout</Nav.Link>
          </Nav> */}
        </BSNavbar.Collapse>
      </Container>
    </BSNavbar>
  )
}