import React from 'react'
import { Form } from 'react-bootstrap'

export const FormCheckRequirements = ({ register }) => {
  const options = [
    {
      value: 'timestamp',
      label: 'Timestamp'
    },
    {
      value: 'evaluatedBy',
      label: 'Evaluated By'
    },
    {
      value: 'departmentCode',
      label: 'Department Code'
    },
    {
      value: 'employeeId',
      label: 'Employee ID'
    },
    {
      value: 'subjectCode',
      label: 'Subject Code'
    },
    {
      value: 'section',
      label: 'Section'
    },
  ]

  return (
    <Form.Group className="mb-3" controlId={register.name}>
      <Form.Label>Require Data</Form.Label>
      <br />
      {
        options
          .map((v, i) =>
            <Form.Check
              key={i}
              inline
              value={v.value}
              label={v.label}
              id={`${register.name}-${v.value}`}
              type="switch"
              // defaultChecked
              {...register}
            />
          )
      }
    </Form.Group>
  )
}