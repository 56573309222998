import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ButtonDeleteConfirm = ({ data, action, children }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleSubmit = async () => {
    try {
      await action()
      await handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Button
        className="p-0"
        variant="link"
        onClick={handleShow}
      >
        <i className="bi bi-trash" />
        Delete
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data? </p>
          {children}
          <p>
            <strong>
              Please take note that this action is not reversible.
              And you are aware of the action you are about to commit
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Yes, I understand
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No, Go back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}