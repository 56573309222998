import React, { useMemo } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { Table } from '../../components/tables/Table'
import { useGetAllSemesterQuery } from './slice/semesterApiSlice'

export const ViewSemester = () => {
  const { data: semesters = [], isLoading, isSuccess } = useGetAllSemesterQuery()

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: '_id',
      Cell: ({ cell: { value } }) => <Link to={`/semesters/edit/${value}`}>View</Link>
    },
    {
      Header: 'Description',
      accessor: 'title'
    },
    {
      Header: 'Code',
      accessor: 'code'
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    }
  ], [])

  const data = useMemo(() => semesters.semesters, [semesters])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card>
      <Card.Body>
        <Card.Title>Semesters</Card.Title>
        <Card.Subtitle>All Semesters</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}