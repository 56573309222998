import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'
import { handleLogin } from './loginSlice'
import { handleAddNotification } from '../../../components/notifications/slice/notificationSlice'

export const loginApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials
      }),
      transformErrorResponse: (response) => response.data.errors.message,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        // console.log(id)
        dispatch(handleAddNotification('Authentication', 'Login Processing', 1000, 'light'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(handleLogin(data))
          dispatch(handleAddNotification('Authentication', 'Login Successful', 3000, 'success'))
        } catch (err) {
          // `onError` side-effect
          console.log(err)
          dispatch(handleAddNotification('Authentication', 'Invalid User Credentials', 3000, 'danger'))
        }
      },
      invalidatesTags: []
    }),
  }),
  overrideExisting: false
})

export const {
  useLoginMutation
} = loginApiSlice