import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const departmentApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllDepartment: builder.query({
      query: () => '/departments',
      providesTags: ['Department']
    }),
    getDepartmentSelection: builder.query({
      query: () => ({
        url: '/departments/selection',
        method: 'GET',
      }),
      providesTags: ['Department']
    }),
    getDepartment: builder.query({
      query: (id) => `/departments/${id}`,
      providesTags: ['Department'],
    }),
    createDepartment: builder.mutation({
      query: (data) => ({
        url: '/departments',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Department']
    }),
    populateDepartmentData: builder.mutation({
      query: () => ({
        url: '/departments/populate',
        method: 'POST',
        params: {
          override: true
        }
      }),
      invalidatesTags: ['Department']
    }),
    updateDepartment: builder.mutation({
      query: (data) => ({
        url: `/departments/${data._id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Department']
    })
  }),
  overrideExisting: false
})

export const {
  useGetAllDepartmentQuery,
  useGetDepartmentQuery,
  useGetDepartmentSelectionQuery,
  useCreateDepartmentMutation,
  usePopulateDepartmentDataMutation,
  useUpdateDepartmentMutation,
} = departmentApiSlice