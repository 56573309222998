import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { docDefinition } from '../../helpers/pdfmake'
import JSZip from 'jszip/dist/jszip'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useGetTemplateQuery } from '../../../form/slice/formApiSlice'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const ButtonDownloadPDF = ({ className, data = [], category }) => {
  const zip = new JSZip()
  const { data: template } = useGetTemplateQuery({ id: data[0].formId })
  const [progress, setProgress] = useState(false)

  const FileOrganization = (entry) => {
    if (category === 'department') {
      return entry.departmentCode + '.pdf'
    }

    if (category === 'employee') {
      return [
        entry.departmentCode,
        `${entry.employeeId}-${entry.employee?.lastName} ${entry.employee?.firstName}.pdf`,
      ].join('/')
    }

    if (category === 'subject') {
      return [
        entry.departmentCode,
        `${entry.employeeId}-${entry.employee?.lastName} ${entry.employee?.firstName}`,
        entry.section,
        `${entry.employeeId}-${entry.employee?.lastName} ${entry.employee?.firstName}-${entry.subjectCode}-${entry.section}.pdf`
      ].join('/')
    }
  }

  const onButtonClicked = async () => {
    try {
      await setProgress(true)

      for (let index = 0; index < data.length; index++) {
        const entry = await data[index]

        await pdfMake
          .createPdf(docDefinition(entry, template, category))
          .getBlob(blob => zip.file(
            FileOrganization(entry),
            blob,
            { blob: true }
          ))
      }

      await zip
        .generateAsync({ type: 'blob' })

      await zip
        .generateAsync({ type: 'blob' })
        .then(blob => saveAs(blob, `Evaluation_Reports_${moment().format('YYYYMMDDHHmmss')}.zip`))

      await setProgress(false)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Button
      className={className}
      variant="primary"
      onClick={onButtonClicked}
      disabled={progress}
    >
      <i className="bi bi-filetype-pdf" />&nbsp;
      {progress ? 'Processing. Please wait' : 'Download PDF'}
    </Button>
  )
}