import React, { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleAnalyticCategory, handleAnalyticFilter } from '../../analytics/slice/analyticSlice'
import { FormSelectAcademicYear } from '../../../components/forms/selections/FormSelectAcademicYear'
import { FormSelectSemester } from '../../../components/forms/selections/FormSelectSemester'
import { FormSelectDepartment } from '../../../components/forms/selections/FormSelectDepartment'
import { FormSelectCategory } from '../../../components/forms/selections/FormSelectCategory'
import { useForm } from 'react-hook-form'
import { useGetProcessedEvaluationQuery } from '../../evaluation/slice/evaluationApiSlice'
import { useParams } from 'react-router-dom'
import { FormSelectEvaluator } from '../../../components/forms/selections/FormSelectEvaluator'

export const FilterAnalytics = ({ departments, templates }) => {
  const dispatch = useDispatch()
  const { category, filter } = useSelector(state => state.analytic)
  const { formId } = useParams()

  const { register } = useForm({
    defaultValues: {
      category: category,
      ...filter
    }
  })

  const filterDepartments = useMemo(() => {
    const groupBy = (x, f) => x
      .reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {})

    const filter = groupBy(departments, v => v.departmentCode)

    // console.log(Object.keys(filter))

    return Object.keys(filter)
  }, [departments])

  const handleFilterChange = e => {
    const { id, value } = e.target
    dispatch(handleAnalyticFilter({ key: id, value: value }))
  }

  const handleFilterCategory = e => {
    dispatch(handleAnalyticCategory(e.target.value))
  }

  return (
    <>
      <Row>
        <Col md={2}>
          <FormSelectCategory
            register={{
              ...register('category', {
                onChange: e => handleFilterCategory(e)
              })
            }}
            list={templates.requirements}
          />
        </Col>
        <Col md={4}>
          <FormSelectDepartment
            register={{
              ...register('departmentCode', {
                onChange: e => handleFilterChange(e)
              })
            }}
            list={departments}
          >
            <option value="">All Departments</option>
          </FormSelectDepartment>
        </Col>
        <Col md={2}>
          <FormSelectEvaluator
            register={{
              ...register('evaluator', {
                onChange: e => handleFilterChange(e)
              })
            }}
            list={templates.category}
          >
            <option value="">All Evaluators</option>
          </FormSelectEvaluator>
        </Col>
        <Col md={2}>
          <FormSelectAcademicYear
            register={{
              ...register('academicYear', {
                onChange: e => handleFilterChange(e)
              })
            }}
          >
            <option value="">All Academic Years</option>
          </FormSelectAcademicYear>
        </Col>
        <Col md={2}>
          <FormSelectSemester
            register={{
              ...register('semester', {
                onChange: e => handleFilterChange(e)
              })
            }}
          >
            <option value="">All Semesters</option>
          </FormSelectSemester>
        </Col>
      </Row>
    </>
  )
}