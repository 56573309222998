import React from 'react'
import { Form, Button, Stack, Row, Col, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { useCreateNewUserMutation, useEditUserMutation, useGetUserQuery } from './slice/userApiSlice'

export const ViewCreateUser = () => {
  const { id } = useParams()

  if (id) return <Edit userId={id} />

  return <FormUser />
}

const Edit = ({ userId }) => {
  const { data: user = {}, isLoading, isSuccess } = useGetUserQuery(userId)

  if (isLoading) return <Spinner />
  if (isSuccess) return <FormUser defaultValue={user} />
}

const FormUser = ({ defaultValue = {} }) => {
  const navigate = useNavigate()

  const { register, handleSubmit, reset, formState: { isDirty, isValid } } = useForm({
    defaultValues: defaultValue
  })

  const [createUser] = useCreateNewUserMutation()
  const [editUser] = useEditUserMutation()

  const handleSubmitClicked = async (data) => {
    try {
      if (data._id) {
        await editUser(data).unwrap()
        // await navigate(`/users/${data._id}`, { replace: true })
        await navigate('/users', { replace: true })
      } else {
        await createUser(data).unwrap()
        await navigate('/users', { replace: true })
      }
      await reset()
    } catch (err) {
      console.error(err)
    }
  }

  const handleBackClicked = async () => {
    try {
      await reset()
      await navigate('/users', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(handleSubmitClicked)}
    >

      <Card>
        <Card.Body>
          <Card.Title>{defaultValue._id ? 'Edit' : 'Create'}&nbsp;User</Card.Title>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  {...register('username', { required: true })}
                  readOnly={defaultValue.username ? true : false}
                  placeholder="Username"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  placeholder="Email Address"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  {...register('firstName', { required: true })}
                  placeholder="First Name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  {...register('lastName', { required: true })}
                  placeholder="Last Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="roles">
                <Form.Label>Roles</Form.Label>
                <Form.Select
                  {...register('roles', { required: true })}
                >
                  <option value="" disabled>Select Role</option>
                  {
                    [
                      { label: 'Administrator', value: 'admin' },
                      { label: 'User', value: 'user' },
                    ].map((c, i) =>
                      <option value={c.value} key={i}>
                        {c.label}
                      </option>
                    )
                  }
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  {...register('password', {
                    required: true,
                    disabled: defaultValue._id ? true : false
                  })}
                  type="password"
                  placeholder="Password"
                  defaultValue="ubaguio2022"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="active">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...register('active', {
                    required: true
                  })}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          <Stack gap={1} direction="horizontal">
            <Button
              onClick={handleBackClicked}
              variant="secondary"
            >
              <i className="bi bi-arrow-left" />&nbsp;
              Back
            </Button>
            <Button
              className="ms-auto"
              type="submit"
              disabled={!isValid || !isDirty}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => reset()}
              variant="secondary"
              disabled={!isDirty}
            >
              Reset
            </Button>
          </Stack>
        </Card.Footer>

      </Card>

    </Form>
  )
}