import React, { useMemo } from 'react'
import { useGetAllDepartmentQuery } from './slice/departmentApiSlice'
import { Table } from '../../components/tables/Table'
import { Spinner } from '../../components/spinners/Spinner'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ViewDepartment = () => {
  const {
    data: departments = [],
    isLoading,
    isSuccess
  } = useGetAllDepartmentQuery()

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: '_id',
      Cell: ({ cell: { value } }) => <Link to={`/departments/edit/${value}`}>View</Link>
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Code',
      accessor: 'code'
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cell: { value } }) => <span className="text-capitalize">{value}</span>
    },
    {
      Header: 'Employees',
      accessor: 'employees'
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ cell: { value } }) => value ? 'Active' : 'Inactive'
    }
  ])

  const data = useMemo(() => departments.departments, [departments])

  if (isLoading) return <Spinner />

  if (isSuccess) return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Departments</Card.Title>
        <Card.Subtitle>All Departments</Card.Subtitle>
        <Table
          columns={columns}
          data={data}
        />
      </Card.Body>
    </Card>
  )
}