import React from 'react'
import { Form, Button, Stack, Row, Col, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '../../components/spinners/Spinner'
import { useCreateDepartmentMutation, useGetDepartmentQuery, useUpdateDepartmentMutation } from './slice/departmentApiSlice'

export const ViewCreateDepartment = () => {
  const { departmentId } = useParams()

  if (departmentId) return <Edit departmentId={departmentId} />

  return <FormDepartment />
}

const Edit = ({ departmentId }) => {
  const { data: semester = {}, isLoading, isSuccess } = useGetDepartmentQuery(departmentId)

  if (isLoading) return <Spinner />
  if (isSuccess) return <FormDepartment defaultValue={semester} />
}

const FormDepartment = ({ defaultValue = { active: true, category: '' } }) => {
  const navigate = useNavigate()

  const { register, handleSubmit, reset, formState: { isDirty, isValid } } = useForm({
    defaultValues: {
      ...defaultValue
    }
  })

  const [createDepartment] = useCreateDepartmentMutation()
  const [editDepartment] = useUpdateDepartmentMutation()

  const handleSubmitClicked = async (data) => {
    try {
      if (data._id) {
        await editDepartment(data).unwrap()
        // await navigate(`/semesters/${data._id}`, { replace: true })
        await navigate('/departments', { replace: true })
      } else {
        await createDepartment(data).unwrap()
        await navigate('/departments', { replace: true })
      }
      await reset()
    } catch (err) {
      console.error(err)
    }
  }

  const handleBackClicked = async () => {
    try {
      await reset()
      await navigate('/departments', { replace: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(handleSubmitClicked)}
    >
      <Card>
        <Card.Body>
          <Card.Title>{defaultValue._id ? 'Edit' : 'Create'} Department</Card.Title>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Department Name"
                  {...register('name', {
                    required: true,
                    minLength: 3
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="code">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  placeholder="Code"
                  readOnly={defaultValue.code ? true : false}
                  {...register('code', {
                    required: true,
                    minLength: 2
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  {...register('category', {
                    required: true
                  })}
                >
                  <option value="" disabled>Select Category</option>
                  <option value="department">Department</option>
                  <option value="office">Office</option>
                  <option value="school">School</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="active">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...register('active', {
                    required: true
                  })}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Stack gap={1} direction="horizontal">
            <Button
              onClick={handleBackClicked}
              variant="secondary"
            >
              <i className="bi bi-arrow-left" />&nbsp;
              Back
            </Button>
            <Button
              className="ms-auto"
              type="submit"
              disabled={!isValid || !isDirty}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => reset()}
              variant="secondary"
              disabled={!isDirty}
            >
              Reset
            </Button>
          </Stack>
        </Card.Footer>
      </Card>


    </Form>
  )
}