import { createSlice } from '@reduxjs/toolkit'
import { appRoutes, adminRoutes, userRoutes } from '../../../routes'

const initialState = {
  offcanvas: false,
  offcanvasWidth: '320px',
  routes: {
    appRoutes: appRoutes,
    adminRoutes: adminRoutes,
    userRoutes: userRoutes,
    featureRoutes: []
  }
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    handleOffcanvasToggle(state, action) {
      state.offcanvas = action.payload
    },
    handleRoutes(state, action) {
      state.routes[action.payload.category] = action.payload.routes
    }
  }
})

export default navigationSlice.reducer
export const {
  handleOffcanvasToggle,
  handleRoutes,
} = navigationSlice.actions