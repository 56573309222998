import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Home } from './features/home/Home'

import { LayoutDepartment } from './features/department/LayoutDepartment'
import { LayoutEmployee } from './features/employees/LayoutEmployee'
import { LayoutFeature } from './components/layouts/LayoutFeature'
import { LayoutPreference } from './features/preference/LayoutPreference'
import { LayoutProtected } from './components/layouts/LayoutProtected'
import { LayoutPublic } from './components/layouts/LayoutPublic'

import { Login } from './features/login/Login'
import { ViewAnalytics } from './features/analytics/ViewAnalytics'
import { ViewCreateForm } from './features/form/ViewCreateForm'
import { ViewCreateSemester } from './features/semesters/ViewCreateSemester'
import { ViewCreateTerm } from './features/terms/ViewCreateTerm'
import { ViewCreateUser } from './features/user/ViewCreateUser'
import { ViewDepartment } from './features/department/ViewDepartment'
import { ViewEmployee } from './features/employees/ViewEmployee'
import { ViewEvaluation } from './features/evaluation/ViewEvaluation'
import { ViewForm } from './features/form/ViewForm'
import { ViewFormInfo } from './features/form/ViewFormInfo'
import { ViewPreference } from './features/preference/ViewPreference'
import { ViewSemester } from './features/semesters/ViewSemester'
import { ViewTerm } from './features/terms/ViewTerm'
import { ViewUser } from './features/user/ViewUser'

import { Notification } from './components/notifications/Notification'
import { ViewCreateDepartment } from './features/department/ViewCreateDepartment'
import { ViewCreateEmployee } from './features/employees/ViewCreateEmployee'
import { Importer } from './features/importer/Importer'
import { Error404 } from './components/errors/Error404'
import { ImportEvaluation } from './features/importer/ImportEvaluation'


function App() {
  return (
    <Router>
      <Routes>

        <Route element={<LayoutPublic />} >
          {/* Insert public routes here */}
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<LayoutProtected />} >
          {/* Insert protected routes here */}
          <Route path="/" element={<Home />} />

          {/* <Route
            path="analytics"
            element={<LayoutFeature routes={[
              {
                link: '/analytics',
                label: 'Analytics'
              },
            ]} />}
          >
            <Route index
              element={<ViewForm
                title="Analytics"
                subtitle="All Analytics"
                view="/analytics"
                hideEmpty={true}
              />}
            />
            <Route path=":formId" element={<ViewAnalytics />} />
          </Route> */}

          <Route path="departments" element={<LayoutDepartment />} >
            <Route index element={<ViewDepartment />} />
            <Route path="create" element={<ViewCreateDepartment />} />
            <Route path="edit/:departmentId" element={<ViewCreateDepartment />} />
          </Route>

          <Route path="employees" element={<LayoutEmployee />} >
            <Route index element={<ViewEmployee />} />
            <Route path="create" element={<ViewCreateEmployee />} />
            <Route path="edit/:employeeId" element={<ViewCreateEmployee />} />
          </Route>

          {/* <Route
            path="evaluations"
            element={<LayoutFeature routes={[
              {
                link: '/evaluations',
                label: 'Evaluations',
                disabled: false
              },
            ]} />}
          >
            <Route index
              element={<ViewForm
                title="Evaluations"
                subtitle="All Evaluations"
                view="/evaluations"
                hideEmpty={true}
              />}
            />
            <Route path=":formId" element={<ViewEvaluation />} />
          </Route> */}

          <Route
            path="evaluations"
            element={<LayoutFeature routes={[
              {
                link: '/evaluations',
                label: 'Evaluations'
              },
              {
                link: '/evaluations/create',
                label: 'Create Tool'
              },
            ]} />}
          >
            <Route index element={<ViewForm />} />
            <Route path="create" element={<ViewCreateForm />} />
            <Route path=":id" element={<ViewFormInfo />} />
            <Route path=":formId/analytics" element={<ViewAnalytics />} />
            <Route path=":formId/data" element={<ViewEvaluation />} />
            <Route path=":id/edit" element={<ViewCreateForm />} />
          </Route>

          <Route
            path="imports"
            element={<LayoutFeature routes={[
              {
                link: '/imports',
                label: 'Logs'
              },
              {
                link: '/imports/evaluation',
                label: 'Import Evaluation'
              },
            ]} />}
          >
            <Route index element={<Importer />} />
            <Route path="evaluation" element={<ImportEvaluation />} />
          </Route>

          <Route path="preferences" element={<LayoutPreference />} >
            <Route index element={<ViewPreference />} />
          </Route>

          <Route
            path="semesters"
            element={<LayoutFeature routes={[
              {
                link: '/semesters',
                label: 'Semesters'
              },
              {
                link: '/semesters/create',
                label: 'Create Semester'
              },
            ]} />}
          >
            <Route index element={<ViewSemester />} />
            <Route path="create" element={<ViewCreateSemester />} />
            <Route path="edit/:id" element={<ViewCreateSemester />} />
          </Route>

          <Route
            path="terms"
            element={<LayoutFeature routes={[
              {
                link: '/terms',
                label: 'Terms'
              },
              {
                link: '/terms/create',
                label: 'Create Term'
              },
            ]} />}
          >
            <Route index element={<ViewTerm />} />
            <Route path="create" element={<ViewCreateTerm />} />
            <Route path="edit/:id" element={<ViewCreateTerm />} />
          </Route>

          <Route
            path="users"
            element={<LayoutFeature routes={[
              {
                link: '/users',
                label: 'Users'
              },
              {
                link: '/users/create',
                label: 'Create User'
              },
            ]} />}
          >
            <Route index element={<ViewUser />} />
            <Route path="create" element={<ViewCreateUser />} />
            <Route path="edit/:id" element={<ViewCreateUser />} />
          </Route>

          <Route path="*" element={<Error404 />} />
        </Route>


      </Routes>
      <Notification />
    </Router >
  )
}

export default App
