import { hrisApiSlice } from '../../../app/slice/hrisApiSlice'

export const semesterApiSlice = hrisApiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllSemester: builder.query({
      query: () => '/semesters',
      providesTags: ['Semester']
    }),
    getSemesterSelection: builder.query({
      query: () => ({
        url: '/semesters/selection',
        method: 'GET',
      }),
      providesTags: ['Semester']
    }),
    getSemester: builder.query({
      query: (id) => `/semesters/${id}`,
      providesTags: ['Semester'],
    }),
    createSemester: builder.mutation({
      query: (body) => ({
        url: '/semesters',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Semester']
    }),
    updateSemester: builder.mutation({
      query: (body) => ({
        url: `/semesters/${body._id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Semester']
    }),
    deleteSemester: builder.mutation({
      query: (id) => ({
        url: `/semesters/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Semester']
    }),
  }),
  overrideExisting: false
})

export const {
  useGetAllSemesterQuery,
  useGetSemesterSelectionQuery,
  useGetSemesterQuery,
  useCreateSemesterMutation,
  useUpdateSemesterMutation,
  useDeleteSemesterMutation
} = semesterApiSlice