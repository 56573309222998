import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { saveAs } from 'file-saver'
import moment from 'moment'

export const ButtonGeneratePDF = ({ data }) => {
  const [status, setStatus] = useState(false)
  const [progress, setProgress] = useState(0)

  const onButtonClicked = () => {
    const worker = new Worker(new URL('./downloadPdf.worker.js', import.meta.url), { type: 'module' })

    setStatus(true)
    setProgress(0)

    worker.postMessage(data)
    worker.onmessage = async (event) => {
      const {
        WORKER_STATUS,
        WORKER_STATUS_MESSAGE,
        WORKER_RESULTS
      } = event.data

      await setProgress(WORKER_STATUS_MESSAGE)

      if (WORKER_STATUS === 'DONE') {
        await saveAs(WORKER_RESULTS, `Evaluation_Reports_${moment().format('YYYYMMDDHHmmss')}.zip`)
        await worker.terminate()
        await setStatus(false)
      }
    }
  }

  const percentage = (n) => (n / (data.evaluations.length - 1) * 100).toFixed(0)

  return (
    <Button
      variant="primary"
      onClick={onButtonClicked}
      disabled={status}
    >
      {!status ?
        <i className="bi bi-filetype-pdf" /> :
        <Spinner as="span" size="sm" animation="border" />
      }
      &nbsp;
      {
        status ?
          `Processing ${percentage(progress)}% (${progress + 1}/${data.evaluations.length})` :
          'Download PDF'
      }
    </Button>
  )
}